// src/components/DefenseParams.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DefenseParams = ({ stats }) => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.BG.background, padding: 1, borderRadius: 1, marginBottom: 2 }}>
      <Box sx={{ backgroundColor: theme.palette.Main.Secondary, padding: 1, borderRadius: 1, marginBottom: 1 }}>
        <Typography variant="h6" sx={{ color: theme.palette.Text.text }}>
          Защитные характеристики
        </Typography>
      </Box>
      <Box>
        {Object.keys(stats).map((key) => (
          stats[key].defaultValue !== 0 && (
          <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ color: theme.palette.Text.text }}>{stats[key].displayName}</Typography>
            <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[key].defaultValue}</Typography>
          </Box>
          )
        ))}
      </Box>
    </Box>
  );
};

export default DefenseParams;
