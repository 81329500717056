// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    BG: {
      background: '#20222C',
      attention: '#FFCC00',
    },
    Main: {
      Primary: '#4776E6',
      Secondary: '#272E43',
    },
    Text: {
      text: '#E9E9EA',
      invert_text: '#20222C',
    },
    Other: {
      icon: '#8C53E6',
      stroke: '#606B85',
      error: '#E03D3D',
    },
  },
});

export default theme;
