import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SkillsStats = ({ stats }) => {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundColor: theme.palette.BG.background, padding: 1, borderRadius: 1, marginBottom: 2 }}>
      <Box sx={{ backgroundColor: theme.palette.Main.Secondary, padding: 1, borderRadius: 1, marginBottom: 1 }}>
        <Typography variant="h6" sx={{ color: theme.palette.Text.text }}>
          Бонусы от умений
        </Typography>
      </Box>
      <Box>
        {Object.keys(stats).map((key) => {
          const value = stats[key];
          if (value.defaultValue > 0) {
            return (
              <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ color: theme.palette.Text.text }}>{value.displayName}</Typography>
                <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{value.defaultValue}</Typography>
              </Box>
            );
          } else {
            return null;
          }
        })}
      </Box>
    </Box>
  );
};

export default SkillsStats;
