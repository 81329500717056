import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as EditIcon } from '../assets/edit.svg';
import { ReactComponent as PlusIcon } from '../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../assets/minus.svg';
import skills from '../data/skills.json';
import { getBonusesForLevel } from '../data/skillBonuses';

const Skills = ({ stats, freePoints, updateStat, updateBonuses, openChoiceDialog, setBonuses, addedSkills, setAddedSkills }) => {
    const theme = useTheme();
    const [editing, setEditing] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedSkill, setSelectedSkill] = useState('');
    const [isEditingSkills, setIsEditingSkills] = useState(false);
    const previousBonuses = useRef({});

    const calculateBonuses = useCallback(() => {
        const bonuses = {};
        Object.keys(addedSkills).forEach((skill) => {
            const level = addedSkills[skill];
            console.log(`Processing skill: ${skill}, level: ${level}`);
            for (let lvl = 1; lvl <= level; lvl++) {
                const bonus = getBonusesForLevel(skill, lvl);
                if (bonus) {
                    Object.keys(bonus).forEach((key) => {
                        if (key === 'choice') {
                            const selectedChoice = bonus.choice.container.find(c => c.option1 === bonuses[skill] || c.option2 === bonuses[skill]);
                            if (selectedChoice) {
                                Object.keys(selectedChoice).forEach((choiceKey) => {
                                    if (choiceKey !== 'option1' && choiceKey !== 'option2') {
                                        bonuses[choiceKey] = (bonuses[choiceKey] || 0) + selectedChoice[choiceKey];
                                        console.log(`Added choice bonus: ${choiceKey}, value: ${selectedChoice[choiceKey]}`);
                                    }
                                });
                            }
                        } else {
                            bonuses[key] = (bonuses[key] || 0) + bonus[key];
                            console.log(`Added bonus: ${key}, value: ${bonus[key]}`);
                        }
                    });
                }
            }
        });
        console.log('Calculated bonuses:', bonuses);
        return bonuses;
    }, [addedSkills]);
    
    useEffect(() => {
        const newBonuses = calculateBonuses();
        if (JSON.stringify(previousBonuses.current) !== JSON.stringify(newBonuses)) {
            previousBonuses.current = newBonuses;
            updateBonuses(newBonuses);
        }
    }, [calculateBonuses, updateBonuses]);
    
    

    // Используем useEffect для обновления бонусов при изменении addedSkills
    useEffect(() => {
        const newBonuses = calculateBonuses();
        if (JSON.stringify(previousBonuses.current) !== JSON.stringify(newBonuses)) {
            previousBonuses.current = newBonuses;
            updateBonuses(newBonuses);
        }
    }, [calculateBonuses, updateBonuses]);

    const toggleEditing = () => {
        setEditing((prev) => !prev);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSkillChange = (event) => {
        setSelectedSkill(event.target.value);
    };

    const handleAddSkill = () => {
        if (selectedSkill && freePoints > 0) {
            setAddedSkills((prevSkills) => ({
                ...prevSkills,
                [selectedSkill]: (prevSkills[selectedSkill] || 0) + 1
            }));
            updateStat('main', 'freeSkillPoints', stats.freeSkillPoints.defaultValue - 1);
            setSelectedSkill('');
            handleClose();
        }
    };

    const handleSkillIncrement = (skill) => {
        if (stats.freeSkillPoints.defaultValue > 0) {
            const newLevel = (addedSkills[skill] || 0) + 1;
            setAddedSkills((prevSkills) => ({
                ...prevSkills,
                [skill]: newLevel,
            }));
            updateStat('main', 'freeSkillPoints', stats.freeSkillPoints.defaultValue - 1);

            const skillBonus = getBonusesForLevel(skill, newLevel);
            if (skillBonus && skillBonus.choice) {
                openChoiceDialog(skill, newLevel, skillBonus.choice.container);
            }
        }
    };

    const handleSkillDecrement = (skill) => {
        if (addedSkills[skill] > 1) {
            const newLevel = addedSkills[skill] - 1;
            setAddedSkills((prevSkills) => ({
                ...prevSkills,
                [skill]: newLevel,
            }));
            updateStat('main', 'freeSkillPoints', stats.freeSkillPoints.defaultValue + 1);

            const skillBonus = getBonusesForLevel(skill, newLevel + 1);
            if (skillBonus) {
                setBonuses((prevBonuses) => {
                    const updatedBonuses = { ...prevBonuses };
                    Object.keys(skillBonus).forEach((bonus) => {
                        if (updatedBonuses[bonus]) {
                            updatedBonuses[bonus] -= skillBonus[bonus];
                            if (updatedBonuses[bonus] <= 0) {
                                delete updatedBonuses[bonus];
                            }
                        }
                    });
                    return updatedBonuses;
                });
            }
        } else {
            const { [skill]: _, ...remainingSkills } = addedSkills;
            setAddedSkills(remainingSkills);
            updateStat('main', 'freeSkillPoints', stats.freeSkillPoints.defaultValue + 1);

            const skillBonus = getBonusesForLevel(skill, 1);
            if (skillBonus) {
                setBonuses((prevBonuses) => {
                    const updatedBonuses = { ...prevBonuses };
                    Object.keys(skillBonus).forEach((bonus) => {
                        if (updatedBonuses[bonus]) {
                            updatedBonuses[bonus] -= skillBonus[bonus];
                            if (updatedBonuses[bonus] <= 0) {
                                delete updatedBonuses[bonus];
                            }
                        }
                    });
                    return updatedBonuses;
                });
            }
        }
    };

    const renderFreeSkillPoints = () => (
        stats.freeSkillPoints?.defaultValue > 0 && (
            <Box sx={{
                display: 'inline-block',
                backgroundColor: theme.palette.BG.attention,
                color: theme.palette.Text.invert_text,
                padding: '0 8px',
                borderRadius: '4px',
                marginLeft: '8px',
                lineHeight: '24px',
            }}>
                {stats.freeSkillPoints.defaultValue}
            </Box>
        )
    );

    const renderAddSkillButton = () => (
        stats.freeSkillPoints?.defaultValue > 0 && (
            <Button
                variant="contained"
                sx={{
                    backgroundColor: theme.palette.Other.icon,
                    color: theme.palette.Text.text,
                    '&:hover': {
                        backgroundColor: theme.palette.Other.icon,
                    },
                    marginTop: 2
                }}
                onClick={handleOpen}
            >
                Добавить умение
            </Button>
        )
    );

    const toggleSkillEditing = () => {
        setIsEditingSkills((prev) => !prev);
    };

    return (
        <Box sx={{ backgroundColor: theme.palette.BG.background, padding: 1, borderRadius: 1, marginBottom: 2 }}>
            <Box sx={{ backgroundColor: theme.palette.Main.Secondary, padding: 1, borderRadius: 1, marginBottom: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ color: theme.palette.Text.text }}>
                    Умения
                    {renderFreeSkillPoints()}
                </Typography>
                <IconButton onClick={toggleSkillEditing}>
                    <EditIcon style={{ fill: theme.palette.Other.icon }} />
                </IconButton>
            </Box>
            <Box>
                {Object.keys(addedSkills).map((key) => (
                    <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ color: theme.palette.Text.text }}>
                            {skills.find(skill => skill.param === key).name_ru}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {isEditingSkills && (
                                <>
                                    <IconButton onClick={() => handleSkillDecrement(key)} disabled={addedSkills[key] <= 0}>
                                        <MinusIcon style={{ fill: theme.palette.Other.minus }} />
                                    </IconButton>
                                    <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{addedSkills[key]}</Typography>
                                    <IconButton onClick={() => handleSkillIncrement(key)} disabled={stats.freeSkillPoints?.defaultValue <= 0}>
                                        <PlusIcon style={{ fill: theme.palette.Other.plus }} />
                                    </IconButton>
                                </>
                            )}
                            {!isEditingSkills && (
                                <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{addedSkills[key]}</Typography>
                            )}
                        </Box>
                    </Box>
                ))}
                {renderAddSkillButton()}
            </Box>

            {/* Модальное окно для добавления умения */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        backgroundColor: theme.palette.BG.modalBackground,
                        color: theme.palette.Text.modalText,
                    },
                }}
            >
                <DialogTitle>Добавить умение</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel sx={{ color: theme.palette.Text.modalText }}>Выберите умение</InputLabel>
                        <Select
                            value={selectedSkill}
                            onChange={handleSkillChange}
                            sx={{ color: theme.palette.Text.modalText }}
                        >
                            {skills.map((skill) => (
                                <MenuItem key={skill.id} value={skill.param}>
                                    {skill.name_ru}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button onClick={handleAddSkill} disabled={!selectedSkill || freePoints <= 0}>Добавить</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Skills;
