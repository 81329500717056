// src/components/PhysicalParams.js
import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as EditIcon } from '../assets/edit.svg';
import { ReactComponent as PlusIcon } from '../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../assets/minus.svg';

const PhysicalParams = ({ stats, freePoints, updateStat }) => {
  const theme = useTheme();
  const [editing, setEditing] = useState(false);

  const toggleEditing = () => {
    setEditing((prev) => !prev);
  };

  const handleIncrement = (stat) => {
    if (freePoints > 0) {
      const newValue = stats[stat].defaultValue + 1;
      updateStat('physical', stat, newValue);
    }
  };

  const handleDecrement = (stat) => {
    const newValue = Math.max(1, stats[stat].defaultValue - 1);
    updateStat('physical', stat, newValue);
  };

  const renderStatControls = (stat) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {editing && (
        <>
          <IconButton onClick={() => handleDecrement(stat)}>
            <MinusIcon style={{ fill: theme.palette.Other.minus }} />
          </IconButton>
          <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[stat].defaultValue}</Typography>
          <IconButton onClick={() => handleIncrement(stat)} disabled={freePoints <= 0}>
            <PlusIcon style={{ fill: theme.palette.Other.plus }} />
          </IconButton>
        </>
      )}
      {!editing && (
        <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[stat].defaultValue}</Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{ backgroundColor: theme.palette.BG.background, padding: 1, borderRadius: 1, marginBottom: 2 }}>
      <Box sx={{ backgroundColor: theme.palette.Main.Secondary, padding: 1, borderRadius: 1, marginBottom: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ color: theme.palette.Text.text }}>
          Физические параметры
          {freePoints > 0 && (
            <Box sx={{ display: 'inline-block', backgroundColor: theme.palette.BG.attention, color: theme.palette.Text.invert_text, padding: '0 8px', borderRadius: '4px', marginLeft: '8px', lineHeight: '24px' }}>
              {freePoints}
            </Box>
          )}
        </Typography>
        <IconButton onClick={toggleEditing}>
          <EditIcon style={{ fill: theme.palette.Other.icon }} />
        </IconButton>
      </Box>
      <Box>
        {Object.keys(stats).map((key) => (
          <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ color: theme.palette.Text.text }}>{stats[key].displayName}</Typography>
            {renderStatControls(key)}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PhysicalParams;
