const optionMapping = {
  armor: 'Броня',
  Resist: 'Магическое сопротивление',
  // Добавьте другие варианты по мере необходимости
};

// Функция для преобразования оригинального значения в отображаемое
const getDisplayOption = (originalOption) => optionMapping[originalOption] || originalOption;

// Функция для преобразования отображаемого значения в оригинальное
const getOriginalOption = (displayOption) => {
  const entry = Object.entries(optionMapping).find(([key, value]) => value === displayOption);
  return entry ? entry[0] : displayOption;
};

const skillBonuses = {
  sc_armor: {
    levels: [
      { level: 1, bonuses: { armor_pool: 1 } },
      { level: 2, bonuses: { armor_pool: 1, Hit_Points: 2 } },
      { level: 3, bonuses: { armor_pool: 1, carryWeight: 1 } },
      { level: 4, bonuses: { armor_pool: 1, Hit_Points: 2, requirement_threshold_armor: 1 } },
      { level: 5, bonuses: { armor_pool: 1, choice: { container: [{ option1: 'armor', value: 1 }, { option2: 'Resist', value: 1 }] } } },
      { level: 6, bonuses: { armor_pool: 1, Hit_Points: 2, carryWeight: 1 } },
      { level: 7, bonuses: { armor_pool: 1 } },
      { level: 8, bonuses: { armor_pool: 1, Hit_Points: 2, requirement_threshold_armor: 1 } },
      { level: 9, bonuses: { armor_pool: 1, carryWeight: 1 } },
      { level: 10, bonuses: { armor_pool: 1, Hit_Points: 2, choice: { container: [{ option1: 'armor', value: 1 }, { option2: 'Resist', value: 1 }] }, reduce_AP_armor_penalty: 1, no_penalty_armor: 5 } }
    ]
  },
  sc_melee_weapons: {
    levels: [
      { level: 1, bonuses: { melee_weapons_pool: 1 } },
      { level: 2, bonuses: { melee_weapons_pool: 1, attack: 1 } },
      { level: 3, bonuses: { melee_weapons_pool: 1, dmg: 1 } },
      { level: 4, bonuses: { melee_weapons_pool: 1, attack: 1, parry: 2 } },
      { level: 5, bonuses: { melee_weapons_pool: 1, requirement_threshold_melee_weapon: 1 } },
      { level: 6, bonuses: { melee_weapons_pool: 1, attack: 1, dmg: 1 } },
      { level: 7, bonuses: { melee_weapons_pool: 1 } },
      { level: 8, bonuses: { melee_weapons_pool: 1, attack: 1, parry: 2 } },
      { level: 9, bonuses: { melee_weapons_pool: 1, dmg: 1 } },
      { level: 10, bonuses: { melee_weapons_pool: 1, attack: 1, requirement_threshold_melee_weapon: 1, rage_per_melee_attack: 2 } }
    ]
  },
    sc_path_of_the_body: {
      levels:
      [
        { level: 1, bonuses: { path_of_the_body_pool: 1 } },
        { level: 2, bonuses: { path_of_the_body_pool: 1, Hit_Points: 1 } },
        { level: 3, bonuses: { path_of_the_body_pool: 1, evasion: 1 } },
        { level: 4, bonuses: { path_of_the_body_pool: 1, Hit_Points: 1, attack: 2 } },
        { level: 5, bonuses: { path_of_the_body_pool: 1, HP_reg: 1, MP_reg: 1 } },
        { level: 6, bonuses: { path_of_the_body_pool: 1, Hit_Points: 1, evasion: 1 } },
        { level: 7, bonuses: { path_of_the_body_pool: 1 } },
        { level: 8, bonuses: { path_of_the_body_pool: 1, Hit_Points: 1, attack: 2 } },
        { level: 9, bonuses: { path_of_the_body_pool: 1, evasion: 1 } },
        { level: 10, bonuses: { path_of_the_body_pool: 1, Hit_Points: 1, HP_reg: 1, MP_reg: 1, armor: 1 } }
      ]

    },

    sc_elemental_magic: {
      levels:
      [
        { level: 1, bonuses: { elemental_magic_pool: 1 } },
        { level: 2, bonuses: { elemental_magic_pool: 1, attack: 1 } },
        { level: 3, bonuses: { elemental_magic_pool: 1, Magic_Points: 1 } },
        { level: 4, bonuses: { elemental_magic_pool: 1, attack: 1, resist_elemental_magic: 1 } },
        { level: 5, bonuses: { elemental_magic_pool: 1, dgm_hill_per_spell: 1} },
        { level: 6, bonuses: { elemental_magic_pool: 1, attack: 1, Magic_Points: 1 } },
        { level: 7, bonuses: { elemental_magic_pool: 1 } },
        { level: 8, bonuses: { elemental_magic_pool: 1, attack: 1, resist_elemental_magic: 1 } },
        { level: 9, bonuses: { elemental_magic_pool: 1, Magic_Points: 1 } },
        { level: 10, bonuses:{ elemental_magic_pool: 1, attack: 1, dgm_hill_per_spell: 1, reduction_MP_costs: 1 } }
      ]

    },
    sc_light_magic: {
      levels:
      [
        { level: 1, bonuses: { light_magic_pool: 1 } },
        { level: 2, bonuses: { light_magic_pool: 1, attack: 1 } },
        { level: 3, bonuses: { light_magic_pool: 1, Magic_Points: 1 } },
        { level: 4, bonuses: { light_magic_pool: 1, attack: 1, Hit_Points: 2 } },
        { level: 5, bonuses: { light_magic_pool: 1, dgm_hill_per_spell: 1} },
        { level: 6, bonuses: { light_magic_pool: 1, attack: 1, Magic_Points: 1 } },
        { level: 7, bonuses: { light_magic_pool: 1 } },
        { level: 8, bonuses: { light_magic_pool: 1, attack: 1, Hit_Points: 2 } },
        { level: 9, bonuses: { light_magic_pool: 1, Magic_Points: 1 } },
        { level: 10, bonuses:{ light_magic_pool: 1, attack: 1, dgm_hill_per_spell: 1, reduction_MP_costs: 1 } }
      ]

    },
    sc_dark_magic: {
      levels:
      [
        { level: 1, bonuses: { dark_magic_pool: 1 } },
        { level: 2, bonuses: { dark_magic_pool: 1, attack: 1 } },
        { level: 3, bonuses: { dark_magic_pool: 1, Magic_Points: 1 } },
        { level: 4, bonuses: { dark_magic_pool: 1, attack: 1, Hit_Points: 2 } },
        { level: 5, bonuses: { dark_magic_pool: 1, dgm_hill_per_spell: 1} },
        { level: 6, bonuses: { dark_magic_pool: 1, attack: 1, Magic_Points: 1 } },
        { level: 7, bonuses: { dark_magic_pool: 1 } },
        { level: 8, bonuses: { dark_magic_pool: 1, attack: 1, Hit_Points: 2 } },
        { level: 9, bonuses: { dark_magic_pool: 1, Magic_Points: 1 } },
        { level: 10, bonuses:{ dark_magic_pool: 1, attack: 1, dgm_hill_per_spell: 1, reduction_MP_costs: 1 } }
      ]

    },
    sc_blood_magic: {
      levels:
      [
        { level: 1, bonuses: { blood_magic_pool: 1, blood_pool: 1 } },
        { level: 2, bonuses: { blood_magic_pool: 1, blood_pool: 1, attack: 1 } },
        { level: 3, bonuses: { blood_magic_pool: 1, blood_pool: 1, Hit_Points: 1 } },
        { level: 4, bonuses: { blood_magic_pool: 1, blood_pool: 1, attack: 1, Hit_Points: 2 } },
        { level: 5, bonuses: { blood_magic_pool: 1, blood_pool: 1, dgm_hill_per_spell: 1} },
        { level: 6, bonuses: { blood_magic_pool: 1, blood_pool: 1, attack: 1, Hit_Points: 1 } },
        { level: 7, bonuses: { blood_magic_pool: 1, blood_pool: 1 } },
        { level: 8, bonuses: { blood_magic_pool: 1, blood_pool: 1, attack: 1, Hit_Points: 2 } },
        { level: 9, bonuses: { blood_magic_pool: 1, blood_pool: 1, Hit_Points: 1 } },
        { level: 10, bonuses:{ blood_magic_pool: 1, blood_pool: 1, attack: 1, dgm_hill_per_spell: 1, reduction_MP_costs: 1 } }
      ]

    },
    sc_rune_magic: {
      levels:
      [
        { level: 1, bonuses: { rune_magic_pool: 1} },
        { level: 2, bonuses: { rune_magic_pool: 1, Magic_Points: 1 } },
        { level: 3, bonuses: { rune_magic_pool: 1, attack: 1 } },
        { level: 4, bonuses: { rune_magic_pool: 1, Magic_Points: 1, Hit_Points: 2 } },
        { level: 5, bonuses: { rune_magic_pool: 1, dgm_hill_per_spell: 1} },
        { level: 6, bonuses: { rune_magic_pool: 1, Magic_Points: 1, attack: 1 } },
        { level: 7, bonuses: { rune_magic_pool: 1, } },
        { level: 8, bonuses: { rune_magic_pool: 1, Magic_Points: 1, Hit_Points: 2 } },
        { level: 9, bonuses: { rune_magic_pool: 1, attack: 1 } },
        { level: 10, bonuses:{ rune_magic_pool: 1, Magic_Points: 1, dgm_hill_per_spell: 1, reduction_MP_costs: 1 } }
      ]

    },

    sc_summoner: {
      levels:
      [
        { level: 1, bonuses: { summoner_pool: 1} },
        { level: 2, bonuses: { summoner_pool: 1, attack: 1, attack_sum: 1} },
        { level: 3, bonuses: { summoner_pool: 1, reflection: 1, reflection_sum: 1 } },
        { level: 4, bonuses: { summoner_pool: 1, attack: 1, attack_sum: 1, resist: 1, resist_sum: 1 } },
        { level: 5, bonuses: { summoner_pool: 1, armor: 1, armor_sum: 1} },
        { level: 6, bonuses: { summoner_pool: 1, attack: 1, attack_sum: 1, reflection: 1, reflection_sum: 1 } },
        { level: 7, bonuses: { summoner_pool: 1, } },
        { level: 8, bonuses: { summoner_pool: 1, attack: 1, attack_sum: 1, resist: 1, resist_sum: 1 } },
        { level: 9, bonuses: { summoner_pool: 1, attack: 1, reflection: 1, reflection_sum: 1 } },
        { level: 10, bonuses:{ summoner_pool: 1, attack: 1, attack_sum: 1, armor: 1, armor_sum: 1, magicDamage: 2,  magicDamage_sum: 2} }
      ]

    },
    sc_astral: { //поправить
      levels:
      [
        { level: 1, bonuses: { astral_pool: 1} },
        { level: 2, bonuses: { astral_pool: 1, Magic_Points: 1} },
        { level: 3, bonuses: { astral_pool: 1, reflection: 1, } },
        { level: 4, bonuses: { astral_pool: 1, Magic_Points: 1, resist: 1} },
        { level: 5, bonuses: { astral_pool: 1, armor: 1, reduction_MP_costs_astral: 1} },
        { level: 6, bonuses: { astral_pool: 1, Magic_Points: 1, reflection: 1} },
        { level: 7, bonuses: { astral_pool: 1, } },
        { level: 8, bonuses: { astral_pool: 1, Magic_Points: 1,  resist: 1} },
        { level: 9, bonuses: { astral_pool: 1, reflection: 1 } },
        { level: 10, bonuses:{ astral_pool: 1, Magic_Points: 1, armor: 1, reduction_MP_costs_astral: 1, reduce_MP_cost_magic: 1} }
      ]

    },
  // Добавьте бонусы для других умений
};

const getBonusesForLevel = (skill, level) => {
  const skillBonus = skillBonuses[skill];
  if (!skillBonus) {
    console.log(`No skill bonus found for skill: ${skill}`);
    return null;
  }
  const cycleLevel = (level - 1) % 10 + 1; // вычисляем уровень в цикле 1-10
  const bonuses = skillBonus.levels.find(b => b.level === cycleLevel)?.bonuses || {};
  console.log(`Bonuses for skill: ${skill}, level: ${level}, cycleLevel: ${cycleLevel}`, bonuses);
  return bonuses;
};


// Преобразование бонусов для отображения на русском языке
const getBonusesForDisplay = (bonuses) => {
  const displayBonuses = { ...bonuses };
  if (displayBonuses.choice && displayBonuses.choice.container) {
    displayBonuses.choice.container = displayBonuses.choice.container.map(choice => ({
      ...choice,
      option1: choice.option1 ? getDisplayOption(choice.option1) : undefined,
      option2: choice.option2 ? getDisplayOption(choice.option2) : undefined,
    }));
  }
  return displayBonuses;
};

// Преобразование отображаемых бонусов в оригинальные
const getBonusesForOriginal = (displayBonuses) => {
  const originalBonuses = { ...displayBonuses };
  if (originalBonuses.choice && originalBonuses.choice.container) {
    originalBonuses.choice.container = originalBonuses.choice.container.map(choice => ({
      ...choice,
      option1: choice.option1 ? getOriginalOption(choice.option1) : undefined,
      option2: choice.option2 ? getOriginalOption(choice.option2) : undefined,
    }));
  }
  return originalBonuses;
};

export { skillBonuses, getBonusesForLevel, getBonusesForDisplay, getBonusesForOriginal, getDisplayOption, getOriginalOption };
