const parameters = {
  main: {
    characterName: { name: "characterName", displayName: "Имя персонажа", defaultValue: "Герой" },
    currentLevel: { name: "currentLevel", displayName: "Текущий уровень", defaultValue: 0 },
    currentExp: { name: "currentExp", displayName: "Текущее количество опыта", defaultValue: 0 },
    requiredExp: { name: "requiredExp", displayName: "Нужное количество опыта", defaultValue: 100 },
    currentHP: { name: "currentHP", displayName: "Текущее состояние здоровья", defaultValue: 30 },
    maxHP: { name: "maxHP", displayName: "Максимальное количество здоровья", defaultValue: 100 },
    currentMP: { name: "currentMP", displayName: "Текущее количество манны", defaultValue: 30 },
    maxMP: { name: "maxMP", displayName: "Максимальное количество манны", defaultValue: 50 },
    freeSkillPoints: { name: "freeSkillPoints", displayName: "Свободные очки умений", defaultValue: 10 },
    freePhysicalPoints: { name: "freePhysicalPoints", displayName: "Свободные очки физических параметров", defaultValue: 25 },
    freeSocialPoints: { name: "freeSocialPoints", displayName: "Свободные очки социальных параметров", defaultValue: 16 }
  },
  physical: {
    strength: { name: "strength", displayName: "Сила", defaultValue: 1 },
    agility: { name: "agility", displayName: "Ловкость", defaultValue: 1 },
    endurance: { name: "endurance", displayName: "Выносливость", defaultValue: 1 },
    intelligence: { name: "intelligence", displayName: "Интеллект", defaultValue: 1 },
    spirit: { name: "spirit", displayName: "Дух", defaultValue: 1 }
  },
  social: {
    charisma: { name: "charisma", displayName: "Харизма", defaultValue: 1 },
    perception: { name: "perception", displayName: "Восприятие", defaultValue: 1 },
    luck: { name: "luck", displayName: "Удача", defaultValue: 1 },
    wisdom: { name: "wisdom", displayName: "Мудрость", defaultValue: 1 }
  },
  attack: {
    attack: { name: "attack", displayName: "Атака", defaultValue: 0 },
    damage: { name: "damage", displayName: "Урон", defaultValue: 0 },
    magicDamage: { name: "magicDamage", displayName: "Магический урон", defaultValue: 0 },
    rangedDamage: { name: "rangedDamage", displayName: "Стрелковый урон", defaultValue: 0 },
    throwDamage: { name: "throwDamage", displayName: "Урон метательного оружия", defaultValue: 0 },
    periodicDamage: { name: "periodicDamage", displayName: "Периодический урон", defaultValue: 0 },
    areaMagicDamage: { name: "areaMagicDamage", displayName: "Площадной магический урон", defaultValue: 0 }
  },
  defense: {
    armor: { name: "armor", displayName: "Броня", defaultValue: 0 },
    evasion: { name: "evasion", displayName: "Уклонение", defaultValue: 0 },
    reflection: { name: "reflection", displayName: "Отражение", defaultValue: 0 }
  },
  other: {
    actionPoints: { name: "actionPoints", displayName: "Очки действия", defaultValue: 0 },
    travelActionPoints: { name: "travelActionPoints", displayName: "Походные очки действия", defaultValue: 0 },
    Carry_Weight: { name: "Carry_Weight", displayName: "Переносимый вес", defaultValue: 0 },
    initiative: { name: "initiative", displayName: "Инициатива", defaultValue: 0 }
  }
};

export default parameters;
