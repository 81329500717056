import React, { useEffect, useState } from 'react';
import { fetchGitHubCommits } from '../api';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme } from '@mui/material';

const PatchNotesModal = ({ lastVisitDate, setShowPatchNotes }) => {
  const [patchNotes, setPatchNotes] = useState([]);
  const theme = useTheme(); // Используем тему из MUI

  useEffect(() => {
    fetchGitHubCommits(lastVisitDate).then(commits => {
      setPatchNotes(commits);
    }).catch(error => {
      console.error('Error fetching commits:', error);
    });
  }, [lastVisitDate]);

  return (
    <Dialog
      open={true}
      onClose={() => setShowPatchNotes(false)}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.BG.background, // Цвет фона из темы
          color: theme.palette.Text.text, // Цвет текста из темы
          padding: theme.spacing(2),
        },
      }}
      BackdropProps={{
        sx: {
          backgroundColor: theme.palette.Main.Secondary, // Цвет фона backdrop из темы
        },
      }}
    >
      <DialogTitle sx={{ color: theme.palette.Text.text }}>Patch Notes</DialogTitle>
      <DialogContent>
        <ul>
          {patchNotes.map((commit, index) => (
            <li key={index}>
              <p>{commit.commit.message}</p>
              <p>{new Date(commit.commit.author.date).toLocaleDateString()}</p>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowPatchNotes(false)} sx={{ color: theme.palette.Text.text }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PatchNotesModal;
