import React, { useState } from 'react';
import { Box, Typography, IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as EditIcon } from '../assets/edit.svg';
import { ReactComponent as PlusIcon } from '../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../assets/minus.svg';
import skills from '../data/skills.json';

const MiniCont = ({ title, stats, freePoints, updateStat }) => {
  const theme = useTheme();
  const [editing, setEditing] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState('');
  const [addedSkills, setAddedSkills] = useState({});
  const [isEditingSkills, setIsEditingSkills] = useState(false);

  const toggleEditing = () => {
    setEditing((prev) => !prev);
  };

  const handleIncrement = (stat) => {
    if (freePoints > 0) {
      const newValue = stats[stat].defaultValue + 1;
      updateStat(title === 'Физические параметры' ? 'physical' : 'social', stat, newValue);
    }
  };

  const handleDecrement = (stat) => {
    const newValue = Math.max(1, stats[stat].defaultValue - 1);
    updateStat(title === 'Физические параметры' ? 'physical' : 'social', stat, newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSkillChange = (event) => {
    setSelectedSkill(event.target.value);
  };

  const handleAddSkill = () => {
    if (selectedSkill && freePoints > 0) {
      setAddedSkills((prevSkills) => ({
        ...prevSkills,
        [selectedSkill]: (prevSkills[selectedSkill] || 0) + 1
      }));
      updateStat('main', 'freeSkillPoints', stats.freeSkillPoints.defaultValue - 1);
      setSelectedSkill('');
      handleClose();
    }
  };

  const handleSkillIncrement = (skill) => {
    if (stats.freeSkillPoints.defaultValue > 0) {
      setAddedSkills((prevSkills) => ({
        ...prevSkills,
        [skill]: prevSkills[skill] + 1,
      }));
      updateStat('main', 'freeSkillPoints', stats.freeSkillPoints.defaultValue - 1);
    }
  };

  const handleSkillDecrement = (skill) => {
    if (addedSkills[skill] > 1) {
      setAddedSkills((prevSkills) => ({
        ...prevSkills,
        [skill]: prevSkills[skill] - 1,
      }));
      updateStat('main', 'freeSkillPoints', stats.freeSkillPoints.defaultValue + 1);
    }
  };

  const renderStatControls = (stat) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {editing && (
        <>
          <IconButton onClick={() => handleDecrement(stat)}>
            <MinusIcon style={{ fill: theme.palette.Other.minus }} />
          </IconButton>
          <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[stat].defaultValue}</Typography>
          <IconButton onClick={() => handleIncrement(stat)} disabled={freePoints <= 0}>
            <PlusIcon style={{ fill: theme.palette.Other.plus }} />
          </IconButton>
        </>
      )}
      {!editing && (
        <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[stat].defaultValue}</Typography>
      )}
    </Box>
  );

  const renderFreePoints = () => (
    freePoints > 0 && (
      <Box sx={{
        display: 'inline-block',
        backgroundColor: theme.palette.BG.attention,
        color: theme.palette.Text.invert_text,
        padding: '0 8px',
        borderRadius: '4px',
        marginLeft: '8px',
        lineHeight: '24px', // Aligns the height with the text height
      }}>
        {freePoints}
      </Box>
    )
  );

  const renderFreeSkillPoints = () => (
    stats.freeSkillPoints?.defaultValue > 0 && (
      <Box sx={{
        display: 'inline-block',
        backgroundColor: theme.palette.BG.attention, // Комментарий: Измените цвет фона здесь
        color: theme.palette.Text.invert_text, // Комментарий: Измените цвет текста здесь
        padding: '0 8px',
        borderRadius: '4px',
        marginLeft: '8px',
        lineHeight: '24px',
      }}>
        {stats.freeSkillPoints.defaultValue}
      </Box>
    )
  );

  const renderAddSkillButton = () => (
    stats.freeSkillPoints?.defaultValue > 0 && (
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.Other.icon,
          color: theme.palette.Text.text,
          '&:hover': {
            backgroundColor: theme.palette.Other.icon,
          },
          marginTop: 2
        }}
        onClick={handleOpen}
      >
        Добавить умение
      </Button>
    )
  );

  const toggleSkillEditing = () => {
    setIsEditingSkills((prev) => !prev);
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.BG.background, padding: 1, borderRadius: 1, marginBottom: 2 }}>
      <Box sx={{ backgroundColor: theme.palette.Main.Secondary, padding: 1, borderRadius: 1, marginBottom: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ color: theme.palette.Text.text }}>
          {title}
          {(title === 'Физические параметры' || title === 'Социальные параметры') && renderFreePoints()}
          {title === 'Умения' && renderFreeSkillPoints()}
        </Typography>
        {title === 'Умения' && (
          <IconButton onClick={toggleSkillEditing}>
            <EditIcon style={{ fill: theme.palette.Other.icon }} />
          </IconButton>
        )}
        {(title === 'Физические параметры' || title === 'Социальные параметры') && (
          <IconButton onClick={toggleEditing}>
            <EditIcon style={{ fill: theme.palette.Other.icon }} />
          </IconButton>
        )}
      </Box>
      {(title === 'Физические параметры' || title === 'Социальные параметры') && (
        <Box>
          {Object.keys(stats).map((key) => (
            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ color: theme.palette.Text.text }}>{stats[key].displayName}</Typography>
              {renderStatControls(key)}
            </Box>
          ))}
        </Box>
      )}
      {title === 'Атакующие характеристики' && (
        <Box>
          {Object.keys(stats).map((key) => (
            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ color: theme.palette.Text.text }}>{stats[key].displayName}</Typography>
              <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[key].defaultValue}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {title === 'Защитные характеристики' && (
        <Box>
          {Object.keys(stats).map((key) => (
            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ color: theme.palette.Text.text }}>{stats[key].displayName}</Typography>
              <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[key].defaultValue}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {title === 'Остальные характеристики' && (
        <Box>
          {Object.keys(stats).map((key) => (
            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ color: theme.palette.Text.text }}>{stats[key].displayName}</Typography>
              <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{stats[key].defaultValue}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {title === 'Умения' && (
        <Box>
          {Object.keys(addedSkills).map((key) => (
            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ color: theme.palette.Text.text }}>{skills[key].name}</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isEditingSkills && (
                  <>
                    <IconButton onClick={() => handleSkillDecrement(key)} disabled={addedSkills[key] <= 1}>
                      <MinusIcon style={{ fill: theme.palette.Other.minus }} />
                    </IconButton>
                    <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{addedSkills[key]}</Typography>
                    <IconButton onClick={() => handleSkillIncrement(key)} disabled={stats.freeSkillPoints?.defaultValue <= 0}>
                      <PlusIcon style={{ fill: theme.palette.Other.plus }} />
                    </IconButton>
                  </>
                )}
                {!isEditingSkills && (
                  <Typography sx={{ mx: 1, color: theme.palette.Text.text }}>{addedSkills[key]}</Typography>
                )}
              </Box>
            </Box>
          ))}
          {renderAddSkillButton()}
        </Box>
      )}

      {/* Модальное окно для добавления умения */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.BG.modalBackground,
            color: theme.palette.Text.modalText,
          },
        }}
      >
        <DialogTitle>Добавить умение</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel sx={{ color: theme.palette.Text.modalText }}>Выберите умение</InputLabel>
            <Select
              value={selectedSkill}
              onChange={handleSkillChange}
              sx={{ color: theme.palette.Text.modalText }}
            >
              {Object.keys(skills).map((skillKey) => (
                <MenuItem key={skillKey} value={skillKey}>
                  {skills[skillKey].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={handleAddSkill} disabled={!selectedSkill || freePoints <= 0}>Добавить</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MiniCont;
