import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, Button, Menu, MenuItem, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as Icon1 } from '../assets/icon1.svg';
import { ReactComponent as Icon2 } from '../assets/icon2.svg';
import { ReactComponent as EditIcon } from '../assets/edit.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import parameters from '../data/parameters';
import { 
  calculateMaxHP, calculateCurrentLevelAndRequiredExp, calculateMaxMP,
  calculateAttack, calculateDamage, calculateMagicDamage, calculateRangedDamage,
  calculateThrowDamage, calculatePeriodicDamage, calculateAreaMagicDamage,
  calculateInitiative,
  calculateCarryWeight, calculateActionPoints, calculateTravelActionPoints, calculateReflection,
  calculateEvasion, calculateArmor, calculateOverloadlight, calculateOverloadMedium,
  calculateResist, calculateFireResist, calculateIceResist, calculateRequirement_threshold_armor,
  calculateReduce_AP_armor_penalty, calculateNo_penalty_armor, calculatePenalty_armor, calculateParry, calculateRequirement_threshold_melee_weapon,
  calculateRage_per_melee_attack,  calculateMeleeWeaponsPool,
  calculateArmorPool,
  calculatePathOfTheBodyPool,
  calculateElementalMagicPool,
  calculateLightMagicPool,
  calculateDarkMagicPool,
  calculateBloodMagicPool,
  calculateRuneMagicPool,
  calculateSummonerPool,
  calculateAstralPool,
  calculateRitualMagicPool,
  calculateThrownWeaponsPool,
  calculateRangedWeaponsPool,
  calculatePotionPulls,
  calculateShieldPool,
  calculateCautionPool,
  calculateSurvivalPool,
  calculateMedicinePool,
  calculateArtOfWarPool,
  calculatePathOfTheSpiritPool,
  calculateUnscrupulousnessPool,
  calculateMasteryOfMagicalToolsPool,
  calculateMerchantPool,
  calculateCraftsmanshipPool,
  calculateBeastmasterPool,
  calculateWitcherPool,
  calculateInquisitorPool,
  calculateNecromancyPool,
  calculateNatureMagicPool,
  calculateHP_reg,
  calculateMP_reg, calculateblood_pool,calculatedgm_hill_per_spell,calculatereduction_MP_costs,calculateAirResist,
  calculateEarthResist,calculateMagicDamageSum,calculateArmorSum,calculateResistSum,calculateReflectionSum,calculateAttackSum,calculatereduce_MP_cost_magic
} from '../data/formulas';
import { getBonusesForDisplay, getBonusesForLevel, getDisplayOption } from '../data/skillBonuses';

import PhysicalParams from './PhysicalParams';
import SocialParams from './SocialParams';
import AttackParams from './AttackParams';
import DefenseParams from './DefenseParams';
import OtherParams from './OtherParams';
import Skills from './Skills';
import Events from './Events';
import SkillsStats from './SkillsStats';

const MainCont = ({ children }) => {
  const theme = useTheme();
  const initialStats = parameters || {};
  const [stats, setStats] = useState(initialStats);
  const [levelInfo, setLevelInfo] = useState({
    currentLevel: initialStats.main?.currentLevel?.defaultValue || 0,
    requiredExp: initialStats.main?.requiredExp?.defaultValue || 100
  });
  const [previousLevel, setPreviousLevel] = useState(initialStats.main?.currentLevel?.defaultValue || 0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [savedFiles, setSavedFiles] = useState([]);
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const [editExpDialogOpen, setEditExpDialogOpen] = useState(false);
  const [newCharacterName, setNewCharacterName] = useState(initialStats.main?.characterName?.defaultValue || '');
  const [newCurrentExp, setNewCurrentExp] = useState(initialStats.main?.currentExp?.defaultValue || 0);
  const [freePhysicalPoints, setFreePhysicalPoints] = useState(initialStats.main?.freePhysicalPoints?.defaultValue || 0);
  const [freeSocialPoints, setFreeSocialPoints] = useState(initialStats.main?.freeSocialPoints?.defaultValue || 0);
  const [bonuses, setBonuses] = useState({});
  const [addedSkills, setAddedSkills] = useState({});
  const [choiceDialogOpen, setChoiceDialogOpen] = useState(false);
  const [currentChoice, setCurrentChoice] = useState([]);
  const [currentSkill, setCurrentSkill] = useState('');
  const [currentLevel, setCurrentLevel] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState('');

  useEffect(() => {
    const { currentLevel, requiredExp } = calculateCurrentLevelAndRequiredExp(stats.main?.currentExp?.defaultValue || 0);
    setLevelInfo({ currentLevel, requiredExp });

    setStats((prevStats) => ({
      ...prevStats,
      main: {
        ...prevStats.main,
        currentLevel,
        requiredExp,
      },
    }));

    if (currentLevel !== previousLevel) {
      let newPhysicalPoints = freePhysicalPoints;
      let newSocialPoints = freeSocialPoints;
      let newSkillPoints = stats.main?.freeSkillPoints?.defaultValue || 0;

      if (currentLevel > previousLevel) {
        for (let lvl = previousLevel + 1; lvl <= currentLevel; lvl++) {
          if (lvl % 5 === 0) {
            newPhysicalPoints += 2;
            newSocialPoints += 1;
          }
          newSkillPoints += 2;
        }
      } else {
        for (let lvl = previousLevel; lvl > currentLevel; lvl--) {
          if (lvl % 5 === 0) {
            newPhysicalPoints -= 2;
            newSocialPoints -= 1;
          }
          newSkillPoints -= 2;
        }
      }

      setFreePhysicalPoints(newPhysicalPoints);
      setFreeSocialPoints(newSocialPoints);
      setStats((prevStats) => ({
        ...prevStats,
        main: {
          ...prevStats.main,
          freeSkillPoints: { ...prevStats.main.freeSkillPoints, defaultValue: newSkillPoints },
        },
      }));
      setPreviousLevel(currentLevel);
    }
  }, [stats.main?.currentExp?.defaultValue]);

  useEffect(() => {
    const files = Object.keys(localStorage);
    setSavedFiles(files);
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSave = () => {
    const characterName = stats.main?.characterName?.defaultValue || 'Unnamed';
    const fileName = prompt("Введите имя файла для сохранения", `${characterName}`);
    if (fileName) {
      const dataToSave = {
        stats,
        bonuses: getBonusesForDisplay(bonuses),
        addedSkills
      };
      localStorage.setItem(fileName, JSON.stringify(dataToSave));
      setSavedFiles(Object.keys(localStorage));
    }
    handleMenuClose();
  };

  const handleLoad = (fileName) => {
    const loadedData = JSON.parse(localStorage.getItem(fileName));
    setStats(loadedData.stats || initialStats);
    setBonuses(getBonusesForDisplay(loadedData.bonuses || {}));
    setAddedSkills(loadedData.addedSkills || {});
    handleMenuClose();
  };

  const handleDelete = (fileName) => {
    localStorage.removeItem(fileName);
    setSavedFiles(Object.keys(localStorage));
  };

  const maxHP = calculateMaxHP(stats, bonuses);
  const maxMP = calculateMaxMP(stats, bonuses);
  const hpRegenBonus = calculateHP_reg(stats, bonuses);
  const mpRegenBonus = calculateMP_reg(stats, bonuses);

  const handleEditNameClick = () => {
    setEditNameDialogOpen(true);
  };

  const handleEditNameClose = () => {
    setEditNameDialogOpen(false);
  };

  const handleEditNameSave = () => {
    setStats((prevStats) => ({
      ...prevStats,
      main: {
        ...prevStats.main,
        characterName: { ...prevStats.main.characterName, defaultValue: newCharacterName },
      },
    }));
    setEditNameDialogOpen(false);
  };

  const handleEditExpClick = () => {
    setEditExpDialogOpen(true);
  };

  const handleEditExpClose = () => {
    setEditExpDialogOpen(false);
  };

  const handleEditExpSave = () => {
    const newExpValue = parseInt(newCurrentExp, 10);
    setStats((prevStats) => ({
      ...prevStats,
      main: {
        ...prevStats.main,
        currentExp: { ...prevStats.main.currentExp, defaultValue: newExpValue },
      },
    }));
    setEditExpDialogOpen(false);
  };

  const getHPColor = () => {
    const hpPercentage = (stats.main?.currentHP?.defaultValue / maxHP) * 100;
    if (hpPercentage < 35) {
      return theme.palette.Other.error;
    } else if (hpPercentage < 75) {
      return theme.palette.BG.attention;
    } else {
      return theme.palette.Text.text;
    }
  };

  const getMPColor = () => {
    const mpPercentage = (stats.main?.currentMP?.defaultValue / maxMP) * 100;
    if (mpPercentage <= 35) {
      return theme.palette.Other.error;
    } else if (mpPercentage <= 75) {
      return theme.palette.BG.attention;
    } else {
      return theme.palette.Text.text;
    }
  };

  const updateStat = (category, stat, newValue) => {
    const diff = newValue - (stats[category]?.[stat]?.defaultValue || 0);

    if (category === 'physical') {
      setFreePhysicalPoints((prev) => Math.max(0, prev - diff));
    } else if (category === 'social') {
      setFreeSocialPoints((prev) => Math.max(0, prev - diff));
    }

    setStats((prevStats) => ({
      ...prevStats,
      [category]: {
        ...prevStats[category],
        [stat]: {
          ...prevStats[category][stat],
          defaultValue: newValue,
        },
      },
    }));
  };

  const calculateAttackStats = () => {
    return {
      attack: { name: 'attack', displayName: 'Атака', defaultValue: calculateAttack(stats, bonuses) },
      damage: { name: 'damage', displayName: 'Урон', defaultValue: calculateDamage(stats, bonuses) },
      magicDamage: { name: 'magicDamage', displayName: 'Магический урон', defaultValue: calculateMagicDamage(stats,bonuses) },
      rangedDamage: { name: 'rangedDamage', displayName: 'Стрелковый урон', defaultValue: calculateRangedDamage(stats,bonuses) },
      throwDamage: { name: 'throwDamage', displayName: 'Урон метательного оружия', defaultValue: calculateThrowDamage(stats,bonuses) },
      periodicDamage: { name: 'periodicDamage', displayName: 'Периодический урон', defaultValue: calculatePeriodicDamage(stats,bonuses) },
      areaMagicDamage: { name: 'areaMagicDamage', displayName: 'Площадной магический урон', defaultValue: calculateAreaMagicDamage(stats,bonuses) },
      attack_sum: { name: 'attack_sum', displayName: 'Атака суммона', defaultValue: calculateAttackSum(stats,bonuses) },
      MagicDamageSum: { name: 'MagicDamageSum', displayName: 'Магический урон суммона', defaultValue: calculateMagicDamageSum(stats,bonuses) },
      // Добавьте остальные характеристики здесь
    };
  };

  const calculateDefenseStats = () => {
    return {
      armor: { name: 'armor', displayName: 'Броня', defaultValue: calculateArmor(stats, bonuses) },
      evasion: { name: 'evasion', displayName: 'Уклонение', defaultValue: calculateEvasion(stats,bonuses) },
      reflection: { name: 'reflection', displayName: 'Отражение', defaultValue: calculateReflection(stats,bonuses) },
      resist: { name: 'resist', displayName: 'Магическое сопротивление', defaultValue: calculateResist(stats, bonuses) },
      fireResist: { name: 'fireResist', displayName: 'Сопротивление огню', defaultValue: calculateFireResist(stats,bonuses) },
      iceResisit: { name: 'iceResisit', displayName: 'Сопротивление Воде', defaultValue: calculateIceResist(stats,bonuses) },
      AirResist: { name: 'AirResist', displayName: 'Сопротивление Воздуху', defaultValue: calculateAirResist(stats,bonuses) },
      EarthResist: { name: 'EarthResist', displayName: 'Сопротивление Земле', defaultValue: calculateEarthResist(stats,bonuses) },
      parry:{name: 'parry', displayName: 'Парирование', defaultValue: calculateParry(stats,bonuses)},
      armor_sum:{name: 'armor_sum', displayName: 'Броня суммона', defaultValue: calculateArmorSum(stats,bonuses)},
      resist_sum:{name: 'resist_sum', displayName: 'Магическое сопративление суммона', defaultValue: calculateResistSum(stats,bonuses)},
      reflection_sum:{name: 'reflection_sum', displayName: 'Отражение суммона', defaultValue: calculateReflectionSum(stats,bonuses)},
      // Добавьте остальные характеристики здесь
    };
  };

  const calculateOtherStats = () => {
    return {
      actionPoints: { name: 'actionPoints', displayName: 'Очки действия', defaultValue: calculateActionPoints(stats,bonuses) },
      travelActionPoints: { name: 'travelActionPoints', displayName: 'Походные очки действия', defaultValue: calculateTravelActionPoints(stats,bonuses) },
      carryWeight: { name: 'carryWeight', displayName: 'Переносимый вес', defaultValue: calculateCarryWeight(stats, bonuses) },
      calculateOverloadlight: { name: 'calculateOverloadlight', displayName: 'Переносимый вес Легий перегруз:', defaultValue: calculateOverloadlight(stats, bonuses) },
      calculateOverloadMedium: { name: 'calculateOverloadMedium', displayName: 'Переносимый вес Cредний перегруз до:', defaultValue: calculateOverloadMedium(stats, bonuses) },
      calculateOverloadMedium: { name: 'calculateOverloadMedium', displayName: 'Переносимый вес Тяжелый перегруз от:', defaultValue: calculateOverloadMedium(stats, bonuses) },
      initiative: { name: 'initiative', displayName: 'Инициатива', defaultValue: calculateInitiative(stats,bonuses) },
      reduce_MP_cost_magiс: { name: 'reduce_MP_cost_magiс', displayName: 'Уменьшение расхода манны на заклинания', defaultValue: calculatereduce_MP_cost_magic(stats,bonuses) },
      // Добавьте остальные характеристики здесь
    };
  };

  const calculateSkillsStats = () => {
    return {
      armor_pool: { name: 'armor_pool', displayName: 'Пул приемов брони', defaultValue: calculateArmorPool(stats, bonuses) },
      requirement_threshold_armor: { name: 'requirement_threshold_armor', displayName: '- к порогу требований Доспехов', defaultValue: calculateRequirement_threshold_armor(stats, bonuses) },
      reduce_AP_armor_penalty: { name: 'reduce_AP_armor_penalty', displayName: 'снижение штрафа од ', defaultValue: calculateReduce_AP_armor_penalty(stats, bonuses) },
      requirement_threshold_melee_weapon: { name: 'requirement_threshold_melee_weapon', displayName: '- к требованиям оружия ближнего боя', defaultValue: calculateRequirement_threshold_melee_weapon(stats, bonuses) },
      rage_per_melee_attack: { name: 'rage_per_melee_attack', displayName: 'Ярости за атаку оружием ближнего боя', defaultValue: calculateRage_per_melee_attack(stats, bonuses) },
      melee_weapons_pool: { name: 'melee_weapons_pool', displayName: 'Пул умений оружия ближнего боя', defaultValue: calculateMeleeWeaponsPool(stats, bonuses) },
      ElementalMagicPool: { name: 'ElementalMagicPool', displayName: 'Пул умений Магмм стихий', defaultValue: calculateElementalMagicPool(stats, bonuses) },
      LightMagicPool: { name: 'LightMagicPool', displayName: 'Пул умений Магии света', defaultValue: calculateLightMagicPool(stats, bonuses) },
      DarkMagicPool: { name: 'DarkMagicPool', displayName: 'Пул умений Магии тьмы', defaultValue: calculateDarkMagicPool(stats, bonuses) },
      BloodMagicPool: { name: 'BloodMagicPool', displayName: 'Пул умений Магии крови', defaultValue: calculateBloodMagicPool(stats, bonuses) },
      RuneMagicPool: { name: 'RuneMagicPool', displayName: 'Пул умений Рунной магии', defaultValue: calculateRuneMagicPool(stats, bonuses) },
      SummonerPool: { name: 'SummonerPool', displayName: 'Пул умений Призывателя', defaultValue: calculateSummonerPool(stats, bonuses) },
      AstralPool: { name: 'AstralPool', displayName: 'Пул умений Астральной магии', defaultValue: calculateAstralPool(stats, bonuses) },
      RitualMagicPool: { name: 'RitualMagicPool', displayName: 'Пул умений Ритуальной магии', defaultValue: calculateRitualMagicPool(stats, bonuses) },
      ThrownWeaponsPool: { name: 'ThrownWeaponsPool', displayName: 'Пул умений Метательного оружия', defaultValue: calculateThrownWeaponsPool(stats, bonuses) },
      RangedWeaponsPool: { name: 'RangedWeaponsPool', displayName: 'Пул умений Стрелковых атак', defaultValue: calculateRangedWeaponsPool(stats, bonuses) },
      PotionPulls: { name: 'PotionPulls', displayName: 'Пул зелий', defaultValue: calculatePotionPulls(stats, bonuses) },
      ShieldPool: { name: 'ShieldPool', displayName: 'Пул умений Щита', defaultValue: calculateShieldPool(stats, bonuses) },
      CautionPool: { name: 'CautionPool', displayName: 'Пул умений Осторожности', defaultValue: calculateCautionPool(stats, bonuses) },
      SurvivalPool: { name: 'SurvivalPool', displayName: 'Пул умений Выживания', defaultValue: calculateSurvivalPool(stats, bonuses) },
      MedicinePool: { name: 'MedicinePool', displayName: 'Пул умений Медицины', defaultValue: calculateMedicinePool(stats, bonuses) },
      ArtOfWarPool: { name: 'ArtOfWarPool', displayName: 'Пул умений Искусстава Войны', defaultValue: calculateArtOfWarPool(stats, bonuses) },
      PathOfTheSpiritPool: { name: 'PathOfTheSpiritPool', displayName: 'Пул умений Пути духа', defaultValue: calculatePathOfTheSpiritPool(stats, bonuses) },
      UnscrupulousnessPool: { name: 'UnscrupulousnessPool', displayName: 'Пул умений Беспренципности', defaultValue: calculateUnscrupulousnessPool(stats, bonuses) },
      MasteryOfMagicalToolsPool: { name: 'MasteryOfMagicalToolsPool', displayName: 'Пул умений Мастера владения магических инструментов', defaultValue: calculateMasteryOfMagicalToolsPool(stats, bonuses) },
      MerchantPool: { name: 'MerchantPool', displayName: 'Пул умений Торговли', defaultValue: calculateMerchantPool(stats, bonuses) },
      CraftsmanshipPool: { name: 'CraftsmanshipPool', displayName: 'Пул умений Ремесла', defaultValue: calculateCraftsmanshipPool(stats, bonuses) },
      BeastmasterPool: { name: 'BeastmasterPool', displayName: 'Пул умений Повелителя зверей', defaultValue: calculateBeastmasterPool(stats, bonuses) },
      WitcherPool: { name: 'WitcherPool', displayName: 'Пул умений Ведьмака', defaultValue: calculateWitcherPool(stats, bonuses) },
      InquisitorPool: { name: 'InquisitorPool', displayName: 'Пул умений Инквизитора', defaultValue: calculateInquisitorPool(stats, bonuses) },
      NecromancyPool: { name: 'NecromancyPool', displayName: 'Пул умений Некромантии', defaultValue: calculateNecromancyPool(stats, bonuses) },
      NatureMagicPool: { name: 'NatureMagicPool', displayName: 'Пул умений магии Природы', defaultValue: calculateNatureMagicPool(stats, bonuses) },
      blood_pool: { name: 'blood_pool', displayName: 'Пул крови', defaultValue: calculateblood_pool(stats, bonuses) },
      dgm_hill_per_spell: { name: 'dgm_hill_per_spell', displayName: 'урона/лечения за круг заклинания', defaultValue: calculatedgm_hill_per_spell(stats, bonuses) },
      reduction_MP_costs: { name: 'reduction_MP_costs', displayName: 'снижение стоимости манны', defaultValue: calculatereduction_MP_costs(stats, bonuses) },
      path_of_the_body_pool: { name: 'path_of_the_body_pool', displayName: 'Пул умений пути тела', defaultValue: calculatePathOfTheBodyPool(stats, bonuses) }

    };
  };

  const handleTakeDamage = (amount, type) => {
    let damage = 0;
    switch (type) {
      case 'physical':
        damage = Math.max(0, amount - calculateArmor(stats, bonuses));
        break;
      case 'magical':
        damage = Math.max(0, amount - calculateResist(stats, bonuses));
        break;
      case 'fire':
        damage = Math.max(0, amount - calculateFireResist(stats));
        break;
      case 'ice':
        damage = Math.max(0, amount - calculateIceResist(stats));
        break;
      case 'primal':
        damage = amount;
        break;
      default:
        damage = amount;
        break;
    }

    const newHP = Math.max(0, (stats.main.currentHP.defaultValue || 0) - damage);
    setStats((prevStats) => ({
      ...prevStats,
      main: {
        ...prevStats.main,
        currentHP: { ...prevStats.main.currentHP, defaultValue: newHP },
      },
    }));
  };

  const handleTakeHeal = (amount) => {
    const newHP = Math.min(maxHP, (stats.main?.currentHP?.defaultValue || 0) + amount);
    setStats((prevStats) => ({
      ...prevStats,
      main: {
        ...prevStats.main,
        currentHP: { ...prevStats.main.currentHP, defaultValue: newHP },
      },
    }));
  };

  const handleTakeManna = (amount) => {
    const newMP = Math.min(maxMP, (stats.main?.currentMP?.defaultValue || 0) + amount);
    setStats((prevStats) => ({
      ...prevStats,
      main: {
        ...prevStats.main,
        currentMP: { ...prevStats.main.currentMP, defaultValue: newMP },
      },
    }));
  };

  const updateBonuses = (prevBonuses, newBonuses) => {
    const updatedBonuses = { ...prevBonuses };
  
    for (const skill in newBonuses) {
      if (newBonuses[skill] === null || newBonuses[skill] === undefined) {
        delete updatedBonuses[skill];
      } else {
        updatedBonuses[skill] = newBonuses[skill];
      }
    }
  
    for (const skill in updatedBonuses) {
      if (updatedBonuses[skill] === null || (typeof updatedBonuses[skill] === 'object' && Object.keys(updatedBonuses[skill]).length === 0)) {
        delete updatedBonuses[skill];
      }
    }
  
    return updatedBonuses;
  };
  
  const handleUpdateBonuses = (newBonuses) => {
    setBonuses((prevBonuses) => {
      const updatedBonuses = updateBonuses(prevBonuses, newBonuses);
  
      if (!newBonuses.Hit_Points && prevBonuses.Hit_Points) {
        delete updatedBonuses.Hit_Points;
      }
  
      return updatedBonuses;
    });
  
    recalculateStats();
  };

  const openChoiceDialog = (skill, level, choice) => {
    setCurrentSkill(skill);
    setCurrentLevel(level);
    setCurrentChoice(choice);
    setChoiceDialogOpen(true);
  };

  const closeChoiceDialog = () => {
    setChoiceDialogOpen(false);
  };

  const confirmChoice = () => {
    setBonuses((prevBonuses) => ({
      ...prevBonuses,
      [currentSkill]: {
        ...prevBonuses[currentSkill],
        [currentLevel]: selectedChoice,
      },
    }));
    setChoiceDialogOpen(false);
    recalculateStats();
  };

  const recalculateStats = () => {
    setStats((prevStats) => {
      const newMaxHP = calculateMaxHP(prevStats, bonuses);
      const newMaxMP = calculateMaxMP(prevStats, bonuses);
      const pools = {
        meleeWeaponsPool: calculateMeleeWeaponsPool(prevStats, bonuses),
        armorPool: calculateArmorPool(prevStats, bonuses),
        pathOfTheBodyPool: calculatePathOfTheBodyPool(prevStats, bonuses),
        elementalMagicPool: calculateElementalMagicPool(prevStats, bonuses),
        lightMagicPool: calculateLightMagicPool(prevStats, bonuses),
        darkMagicPool: calculateDarkMagicPool(prevStats, bonuses),
        bloodMagicPool: calculateBloodMagicPool(prevStats, bonuses),
        runeMagicPool: calculateRuneMagicPool(prevStats, bonuses),
        summonerPool: calculateSummonerPool(prevStats, bonuses),
        astralPool: calculateAstralPool(prevStats, bonuses),
        ritualMagicPool: calculateRitualMagicPool(prevStats, bonuses),
        thrownWeaponsPool: calculateThrownWeaponsPool(prevStats, bonuses),
        rangedWeaponsPool: calculateRangedWeaponsPool(prevStats, bonuses),
        potionPulls: calculatePotionPulls(prevStats, bonuses),
        shieldPool: calculateShieldPool(prevStats, bonuses),
        cautionPool: calculateCautionPool(prevStats, bonuses),
        survivalPool: calculateSurvivalPool(prevStats, bonuses),
        medicinePool: calculateMedicinePool(prevStats, bonuses),
        artOfWarPool: calculateArtOfWarPool(prevStats, bonuses),
        pathOfTheSpiritPool: calculatePathOfTheSpiritPool(prevStats, bonuses),
        unscrupulousnessPool: calculateUnscrupulousnessPool(prevStats, bonuses),
        masteryOfMagicalToolsPool: calculateMasteryOfMagicalToolsPool(prevStats, bonuses),
        merchantPool: calculateMerchantPool(prevStats, bonuses),
        craftsmanshipPool: calculateCraftsmanshipPool(prevStats, bonuses),
        beastmasterPool: calculateBeastmasterPool(prevStats, bonuses),
        witcherPool: calculateWitcherPool(prevStats, bonuses),
        inquisitorPool: calculateInquisitorPool(prevStats, bonuses),
        necromancyPool: calculateNecromancyPool(prevStats, bonuses),
        natureMagicPool: calculateNatureMagicPool(prevStats, bonuses)
      };
  
      const updatedMain = {
        ...prevStats.main,
        maxHP: { ...prevStats.main.maxHP, defaultValue: newMaxHP },
        maxMP: { ...prevStats.main.maxMP, defaultValue: newMaxMP },
        meleeWeaponsPool: pools.meleeWeaponsPool,
        armorPool: pools.armorPool,
        pathOfTheBodyPool: pools.pathOfTheBodyPool,
        elementalMagicPool: pools.elementalMagicPool,
        lightMagicPool: pools.lightMagicPool,
        darkMagicPool: pools.darkMagicPool,
        bloodMagicPool: pools.bloodMagicPool,
        runeMagicPool: pools.runeMagicPool,
        summonerPool: pools.summonerPool,
        astralPool: pools.astralPool,
        ritualMagicPool: pools.ritualMagicPool,
        thrownWeaponsPool: pools.thrownWeaponsPool,
        rangedWeaponsPool: pools.rangedWeaponsPool,
        potionPulls: pools.potionPulls,
        shieldPool: pools.shieldPool,
        cautionPool: pools.cautionPool,
        survivalPool: pools.survivalPool,
        medicinePool: pools.medicinePool,
        artOfWarPool: pools.artOfWarPool,
        pathOfTheSpiritPool: pools.pathOfTheSpiritPool,
        unscrupulousnessPool: pools.unscrupulousnessPool,
        masteryOfMagicalToolsPool: pools.masteryOfMagicalToolsPool,
        merchantPool: pools.merchantPool,
        craftsmanshipPool: pools.craftsmanshipPool,
        beastmasterPool: pools.beastmasterPool,
        witcherPool: pools.witcherPool,
        inquisitorPool: pools.inquisitorPool,
        necromancyPool: pools.necromancyPool,
        natureMagicPool: pools.natureMagicPool
      };
  
      const updatedAttack = calculateAttackStats();
      const updatedDefense = calculateDefenseStats();
      const updatedOther = calculateOtherStats();
      const updatedSkills = calculateSkillsStats();
  
      const updatedStats = {
        ...prevStats,
        main: updatedMain,
        attack: updatedAttack,
        defense: updatedDefense,
        other: updatedOther,
        skills: updatedSkills
      };
  
      return updatedStats;
    });
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.BG.background, padding: 2, borderRadius: 1 }}>
      <Box sx={{ backgroundColor: theme.palette.Main.Secondary, padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
          <Icon1 style={{ fill: theme.palette.Other.icon, marginRight: '10px' }} />
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Typography variant="h6" sx={{ color: theme.palette.Text.text }}>
              Имя персонажа: {stats.main?.characterName?.defaultValue || 'Unnamed'}
            </Typography>
            <IconButton onClick={handleEditNameClick}>
              <EditIcon style={{ fill: theme.palette.Other.icon }} />
            </IconButton>
          </Box>
          <Button onClick={handleMenuOpen}>
            <Icon2 style={{ fill: theme.palette.Other.icon, marginLeft: '10px' }} />
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleSave}>Сохранить данные</MenuItem>
            {savedFiles.length > 0 &&
              [<Typography sx={{ padding: 1 }} key="header">Сохраненные файлы</Typography>].concat(
                savedFiles.map((fileName) => (
                  <MenuItem key={fileName} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography onClick={() => handleLoad(fileName)} sx={{ flexGrow: 1 }}>
                      {fileName}
                    </Typography>
                    <IconButton onClick={() => handleDelete(fileName)} size="small">
                      <DeleteIcon style={{ fill: theme.palette.Other.icon }} />
                    </IconButton>
                  </MenuItem>
                )),
              )}
          </Menu>
        </Box>

        <Box sx={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ color: theme.palette.Text.text }}>
            LVL {levelInfo.currentLevel}
          </Typography>
          <IconButton onClick={handleEditExpClick}>
            <EditIcon style={{ fill: theme.palette.Other.icon }} />
          </IconButton>
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body2" sx={{ color: theme.palette.Text.text }}>
            XP {stats.main?.currentExp?.defaultValue || 0}/{levelInfo.requiredExp}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(stats.main?.currentExp?.defaultValue || 0) / levelInfo.requiredExp * 100}
            sx={{
              backgroundColor: theme.palette.Other.stroke,
              height: 10,
              '& .MuiLinearProgress-bar': {
                backgroundColor: theme.palette.Main.Primary,
              },
            }}
          />
        </Box>

        {/* Полоски HP и MP */}
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" sx={{ color: getHPColor() }}>
              HP {stats.main?.currentHP?.defaultValue || 0}/{maxHP}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(stats.main?.currentHP?.defaultValue || 0) / maxHP * 100}
              sx={{
                backgroundColor: theme.palette.Other.stroke,
                height: 10,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: getHPColor(),
                },
              }}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" sx={{ color: getMPColor() }}>
              MP {stats.main?.currentMP?.defaultValue || 0}/{maxMP}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(stats.main?.currentMP?.defaultValue || 0) / maxMP * 100}
              sx={{
                backgroundColor: theme.palette.Other.stroke,
                height: 10,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: getMPColor(),
                },
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box sx={{ backgroundColor: theme.palette.Main.BodyBackground, padding: 2, borderRadius: 1 }}>
        <PhysicalParams stats={stats.physical} freePoints={freePhysicalPoints} updateStat={updateStat} />
        <SocialParams stats={stats.social} freePoints={freeSocialPoints} updateStat={updateStat} />
        <AttackParams stats={calculateAttackStats()} />
        <DefenseParams stats={calculateDefenseStats(stats, bonuses)} />
        <OtherParams stats={calculateOtherStats()} />
        <Skills stats={stats.main} freePoints={stats.main?.freeSkillPoints?.defaultValue || 0} updateStat={updateStat} updateBonuses={handleUpdateBonuses} openChoiceDialog={openChoiceDialog} setBonuses={setBonuses} addedSkills={addedSkills} setAddedSkills={setAddedSkills} />
        <SkillsStats stats={calculateSkillsStats()}/>
        <Events 
          onTakeDamage={handleTakeDamage} 
          onTakeHeal={handleTakeHeal} 
          onTakeManna={handleTakeManna}
          maxHP={maxHP}
          maxMP={maxMP}
          bonuses={{
            ...bonuses,
            calculateHP_reg: hpRegenBonus,
            calculateMP_reg: mpRegenBonus,
          }}
        />
      </Box>

      {/* Диалог редактирования имени */}
      <Dialog
        open={editNameDialogOpen}
        onClose={handleEditNameClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.Other.stroke,
            color: theme.palette.Text.text,
          },
        }}
      >
        <DialogTitle sx={{ color: theme.palette.Text.text }}>Редактировать имя персонажа</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Новое имя"
            fullWidth
            variant="standard"
            value={newCharacterName}
            onChange={(e) => setNewCharacterName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditNameClose}>Отмена</Button>
          <Button onClick={handleEditNameSave}>Сохранить</Button>
        </DialogActions>
      </Dialog>

      {/* Диалог редактирования опыта */}
      <Dialog
        open={editExpDialogOpen}
        onClose={handleEditExpClose}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.Other.stroke,
            color: theme.palette.Text.text,
          },
        }}
      >
        <DialogTitle sx={{ color: theme.palette.Text.text }}>Редактировать опыт персонажа</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Текущий опыт"
            fullWidth
            variant="standard"
            value={newCurrentExp}
            onChange={(e) => setNewCurrentExp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditExpClose}>Отмена</Button>
          <Button onClick={handleEditExpSave}>Сохранить</Button>
        </DialogActions>
      </Dialog>

      {/* Модальное окно для выбора бонусов */}
      <Dialog
        open={choiceDialogOpen}
        onClose={closeChoiceDialog}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.BG.modalBackground,
            color: theme.palette.Text.modalText,
          },
        }}
      >
        <DialogTitle>Выберите бонус для {currentSkill} на уровне {currentLevel}</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={selectedChoice}
            onChange={(e) => setSelectedChoice(e.target.value)}
          >
            {currentChoice?.filter(option => option.option1 || option.option2).map((option, index) => (
              <React.Fragment key={index}>
                {option.option1 && <FormControlLabel value={option.option1} control={<Radio />} label={getDisplayOption(option.option1)} />}
                {option.option2 && <FormControlLabel value={option.option2} control={<Radio />} label={getDisplayOption(option.option2)} />}
              </React.Fragment>
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeChoiceDialog}>Отмена</Button>
          <Button onClick={confirmChoice} disabled={!selectedChoice}>Подтвердить</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MainCont;
