// src/App.js
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import MainCont from './components/MainCont';
import MiniCont from './components/MiniCont';
import PatchNotesModal from './components/PatchNotesModal';
import theme from './theme';
import { fetchGitHubCommits } from './api';

const App = () => {
  const [showPatchNotes, setShowPatchNotes] = useState(false);
  const [lastVisitDate, setLastVisitDate] = useState(null);

  useEffect(() => {
    const lastVisit = localStorage.getItem('lastVisit');
    setLastVisitDate(lastVisit);

    // Fetch GitHub commits and compare dates
    fetchGitHubCommits(lastVisit)
      .then(commits => {
        if (commits.length > 0) {
          setShowPatchNotes(true);
        }
      })
      .catch(error => {
        console.error('Error fetching commits:', error);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        {showPatchNotes && <PatchNotesModal lastVisitDate={lastVisitDate} setShowPatchNotes={setShowPatchNotes} />}
        <MainCont>
          <MiniCont title="Физические параметры" description="Сила, ловкость, выносливость, Интелект, Дух" />
          <MiniCont title="Социальные параметры" description="Харизма, восприятие, удача, мудрость" />
          <MiniCont title="Атакующие характеристики" description="Атака, защита, броня, отражение" />
          <MiniCont title="Защитные характеристики" description="Защита от магии, броня, сопротивление" />
          <MiniCont title="Остальные характеристики" description="Энергия, регенерация" />
          <MiniCont title="Умения" description="Описание умений" />
          <MiniCont title="Бонусы от умений" description="Описание бонусов" />
          <MiniCont title="События" description="События" />
        </MainCont>
      </div>
    </ThemeProvider>
  );
};

export default App;
