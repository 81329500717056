// src/api.js

const GITHUB_TOKEN = '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';

export const fetchGitHubCommits = async (lastVisitDate) => {
  try {
    const response = await fetch('https://api.github.com/repos/zLegioneRz/PiratEngine/commits?sha=test', {
      headers: {
        'Authorization': `token ${GITHUB_TOKEN}`
      }
    });
    if (!response.ok) {
      throw new Error(`GitHub API responded with status ${response.status}`);
    }
    const data = await response.json();
    if (!Array.isArray(data)) {
      throw new Error('API response is not an array');
    }
    return data.filter(commit => new Date(commit.commit.author.date) > new Date(lastVisitDate));
  } catch (error) {
    console.error('Error fetching commits:', error);
    throw error;
  }
};
