// src/components/Events.js
import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Events = ({ onTakeDamage, onTakeHeal, onTakeManna, bonuses, maxHP, maxMP }) => {
  const theme = useTheme();
  const [damageDialogOpen, setDamageDialogOpen] = useState(false);
  const [damageAmount, setDamageAmount] = useState('');
  const [damageType, setDamageType] = useState('physical'); // Default to 'physical'
  const [healDialogOpen, setHealDialogOpen] = useState(false);
  const [healAmount, setHealAmount] = useState('');
  const [MannaDialogOpen, setMannaDialogOpen] = useState(false);
  const [MannaAmount, setMannaAmount] = useState('');

  const hpRegenPercent = 0.1; // Пример значения 10%
  const mpRegenPercent = 0.1; // Пример значения 10%

  const handleOpenDamageDialog = () => {
    setDamageDialogOpen(true);
  };

  const handleCloseDamageDialog = () => {
    setDamageDialogOpen(false);
  };

  const handleDamageChange = (event) => {
    setDamageAmount(event.target.value);
  };

  const handleDamageTypeChange = (event) => {
    setDamageType(event.target.value);
  };

  const handleTakeDamage = () => {
    onTakeDamage(parseInt(damageAmount, 10), damageType);
    setDamageDialogOpen(false);
    setDamageAmount('');
    setDamageType('physical');
  };

  const handleOpenHealDialog = () => {
    setHealDialogOpen(true);
  };

  const handleCloseHealDialog = () => {
    setHealDialogOpen(false);
  };

  const handleHealChange = (event) => {
    setHealAmount(event.target.value);
  };

  const handleTakeHeal = () => {
    onTakeHeal(parseInt(healAmount, 10));
    setHealDialogOpen(false);
    setHealAmount('');
  };

  const handleOpenMannaDialog = () => {
    setMannaDialogOpen(true);
  };

  const handleCloseMannaDialog = () => {
    setMannaDialogOpen(false);
  };

  const handleMannaChange = (event) => {
    setMannaAmount(event.target.value);
  };

  const handleTakeManna = () => {
    onTakeManna(parseInt(MannaAmount, 10));
    setMannaDialogOpen(false);
    setMannaAmount('');
  };

  const handleDailyRegen = () => {
    const hitPointsBonus = bonuses.calculateHP_reg;
    console.log('hitPointsBonus:', hitPointsBonus);
    const mannaPointsBonus = bonuses.calculateMP_reg;
    console.log('mannaPointsBonus:', mannaPointsBonus);
    const newHealAmount = Math.round(maxHP * hpRegenPercent) + hitPointsBonus;
    const newMannaAmount = Math.round(maxMP * mpRegenPercent) + mannaPointsBonus;
    onTakeHeal(newHealAmount);
    onTakeManna(newMannaAmount);
  };

  return (
    <Box sx={{ backgroundColor: theme.palette.BG.background, padding: 1, borderRadius: 1, marginBottom: 2 }}>
      <Box sx={{ backgroundColor: theme.palette.Main.Secondary, padding: 1, borderRadius: 1, marginBottom: 1 }}>
        <Typography variant="h6" sx={{ color: theme.palette.Text.text }}>
          События
        </Typography>
      </Box>
      <Box>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.Other.icon, color: theme.palette.Text.text, marginBottom: 1, marginRight: 1 }}
          onClick={handleOpenDamageDialog}
        >
          Получить урон
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.Other.icon, color: theme.palette.Text.text, marginBottom: 1, marginRight: 1 }}
          onClick={handleOpenHealDialog}
        >
          Получить Исцеление
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.Other.icon, color: theme.palette.Text.text, marginBottom: 1, marginRight: 1 }}
          onClick={handleOpenMannaDialog}
        >
          Получить Манну
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.Other.icon, color: theme.palette.Text.text, marginBottom: 1, marginRight: 1 }}
          onClick={handleDailyRegen}
        >
          Суточный реген
        </Button>
      </Box>

      {/* Диалоговое окно для получения урона */}
      <Dialog open={damageDialogOpen} onClose={handleCloseDamageDialog}>
        <DialogTitle>Получить урон</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Количество урона"
            type="number"
            fullWidth
            variant="standard"
            value={damageAmount}
            onChange={handleDamageChange}
          />
          <RadioGroup value={damageType} onChange={handleDamageTypeChange} row>
            <FormControlLabel value="physical" control={<Radio />} label="Физический урон" />
            <FormControlLabel value="magical" control={<Radio />} label="Магический урон" />
            <FormControlLabel value="fire" control={<Radio />} label="Огненный урон" />
            <FormControlLabel value="ice" control={<Radio />} label="Ледяной урон" />
            <FormControlLabel value="primal" control={<Radio />} label="Прямой урон" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDamageDialog}>Отмена</Button>
          <Button onClick={handleTakeDamage} disabled={!damageAmount}>Применить</Button>
        </DialogActions>
      </Dialog>

      {/* Диалоговое окно для получения исцеления */}
      <Dialog open={healDialogOpen} onClose={handleCloseHealDialog}>
        <DialogTitle>Получить Хил</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Количество исцеления"
            type="number"
            fullWidth
            variant="standard"
            value={healAmount}
            onChange={handleHealChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHealDialog}>Отмена</Button>
          <Button onClick={handleTakeHeal} disabled={!healAmount}>Применить</Button>
        </DialogActions>
      </Dialog>

      {/* Диалоговое окно для получения Манны */}
      <Dialog open={MannaDialogOpen} onClose={handleCloseMannaDialog}>
        <DialogTitle>Получить Манну</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Количество Манны"
            type="number"
            fullWidth
            variant="standard"
            value={MannaAmount}
            onChange={handleMannaChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMannaDialog}>Отмена</Button>
          <Button onClick={handleTakeManna} disabled={!MannaAmount}>Применить</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Events;
