import experienceTable from './exp_tbl.json';

// Универсальная функция для расчета бонусов с поддержкой различных операций
const calculateBonus = (stats, bonuses, key, baseValue = 0, factor = 1, operation = '+') => {
  // Получаем значение стата по ключу или 0, если стат отсутствует
  const statValue = stats.physical?.[key]?.defaultValue || 0;

  // Рассчитываем базовое значение с учетом операции и множителя
  let base;
  switch (operation) {
    case '+':
      base = baseValue + (statValue * factor);
      break;
    case '-':
      base = baseValue - (statValue * factor);
      break;
    case '*':
      // Учитываем случаи умножения на 0
      base = baseValue === 0 ? statValue * factor : baseValue * (statValue * factor);
      break;
    case '/':
      if (statValue === 0) {
        throw new Error('Division by zero error');
      }
      // Учитываем случаи деления на 0
      base = baseValue === 0 ? statValue / factor : baseValue / (statValue * factor);
      break;
    default:
      throw new Error(`Unsupported operation: ${operation}`);
  }

  // Суммируем все бонусы по ключу
  const bonus = Object.values(bonuses).reduce((sum, skill) => sum + (skill?.[key] || 0), 0);

  // Добавляем дополнительные бонусы
  const additionalBonus = bonuses[key] || 0;

  // Возвращаем итоговое значение
  return base + bonus + additionalBonus;
};

const calculateMaxHP = (stats, bonuses) => {
  const penaltyArmor = calculatePenalty_armor(stats, bonuses);
  const noPenaltyArmorBonus = calculateNo_penalty_armor(stats, bonuses);
  let baseHP = 30 + (stats.physical.endurance.defaultValue * 5);
  let hpBonus = 0;
  
  // Проверяем все навыки и уровни для бонусов на HP
  for (const skill in bonuses) {
    if (bonuses[skill] && bonuses[skill].hasOwnProperty('Hit_Points')) {
      hpBonus += bonuses[skill].Hit_Points;
    }
  }

  // Учитываем только явное наличие additionalBonusHP
  let additionalBonusHP = 0;
  if (bonuses.Hit_Points) {
    additionalBonusHP = bonuses.Hit_Points;
  }

  if (penaltyArmor === 0 )
    hpBonus +=  noPenaltyArmorBonus

  const totalHP = baseHP + hpBonus + additionalBonusHP;

  return totalHP;
};


const calculateMaxMP = (stats, bonuses) => {
  let baseMP = 30 + (stats.physical.spirit.defaultValue * 5);
  let MPbonus = 0;

  for (const skill in bonuses) {
    if (bonuses[skill] && bonuses[skill].hasOwnProperty('Magic_Points')) {
      MPbonus += bonuses[skill].Magic_Points;
    }
  }
   
  let additionalBonusMP = 0;
  if (bonuses.Magic_Points) {
    additionalBonusMP = bonuses.Magic_Points;
  }

  const totalMP = baseMP + MPbonus + additionalBonusMP;

  return totalMP;
};

const calculateCurrentLevelAndRequiredExp = (currentExp) => {
  let currentLevel = 0;
  let requiredExp = 0;

  for (let i = 0; i < experienceTable.experienceTable.length; i++) {
    if (currentExp < experienceTable.experienceTable[i].exp) {
      requiredExp = experienceTable.experienceTable[i].exp;
      break;
    }
    currentLevel = experienceTable.experienceTable[i].level;
  }

  return { currentLevel, requiredExp };
};

const calculateRuneMagicPool = (stats,bonuses) => {
  return calculateBonus(stats,bonuses,'rune_magic_pool');
};

const calculateAttack = (stats, bonuses) => {
  const baseAttack = 30;
  const agilityBonus = calculateBonus(stats, bonuses, 'agility', 0, 2,'*'); // Бонус от ловкости умножается на 2
  const additionalAttackBonus = bonuses.attack || 0;
  return baseAttack + agilityBonus + additionalAttackBonus;
};

const calculateDamage = (stats, bonuses) => {
  const baseDamage = 5;
  const strengthBonus = calculateBonus(stats, bonuses, 'strength', 0, 1, '+');
  const additionalDamage = bonuses.damage || 0;
  return baseDamage + strengthBonus + additionalDamage;
};

const calculateMagicDamage = (stat, bonuses) => {
  const base = 0;
  const bonus = calculateBonus(stat,bonuses, 'intelligence', 0,1);
  const additionalBonus = bonuses.magicDamage ||0;
  return  base + bonus +additionalBonus;
};

const calculateRangedDamage =  (stat,bonuses) => {
  const base = 0;
  const bonus = calculateBonus(stat,bonuses, 'strength' , 0,1);
  const additionalBonus = bonuses.RangedDamage ||0;
  return base + bonus + additionalBonus;
};

const calculateThrowDamage =  (stat,bonuses) => {
  const base = 0;
  const bonus = calculateBonus(stat,bonuses, 'strength' , 0,5,'*');
  const additionalBonus = bonuses.ThrowDamage ||0;
  return base + bonus + additionalBonus;
};

const calculatePeriodicDamage =  (stat,bonuses) => {
  const base = 0;
  const bonus = calculateBonus(stat,bonuses, 'intelligence' , 0,2,'/');
  const additionalBonus = bonuses.PeriodicDamage ||0;
  return Math.round(base + bonus + additionalBonus);
};

const calculateAreaMagicDamage =  (stat,bonuses) => {
  const base = 0;
  const bonus = calculateBonus(stat,bonuses, 'intelligence' , 0,2,'/');
  const additionalBonus = bonuses.AreaMagicDamage ||0;
  return Math.round(base + bonus + additionalBonus);
};


const calculateArmor = (stats, bonuses = {}) => {
  const base = 0;
  const armorBonus = calculateBonus(stats, bonuses, 'armor', base); // Используем calculateBonus для расчета бонуса от stats
  let armor = armorBonus + (bonuses.armor || 0); // Добавляем бонусы из bonuses

  if (bonuses.sc_armor) {
    for (const level in bonuses.sc_armor) {
      if (bonuses.sc_armor[level] === 'armor') {
        armor += 1; // Добавляем дополнительные бонусы из sc_armor
      }
    }
  }

  return armor;
};

const calculateEvasion = (stat, bonuses) => {
  const base = 5;
  const bonus = calculateBonus(stat,bonuses, 'agility', 0,1,'+');
  const additionalBonus = bonuses.Evasion ||0;
  return  base + bonus + additionalBonus;
};

const calculateReflection = (stat, bonuses) => {
  const base = 5;
  const bonus = calculateBonus(stat,bonuses, 'intelligence', 0,1,'+');
  const additionalBonus = bonuses.reflection ||0;
  return  base + bonus + additionalBonus;
};

const calculateTravelActionPoints = (stat, bonuses) => {
  const base = 4;
  const bonus = calculateBonus(stat,bonuses, 'endurance', 0,2,'/');
  const additionalBonus = bonuses.TravelActionPoints ||0;
  return  Math.round(base + bonus + additionalBonus);
};

const calculateActionPoints = (stat, bonuses) => {
  const base = 20;
  const bonus = 0
  const additionalBonus = bonuses.ActionPoints ||0;
  return  Math.round(base + bonus + additionalBonus);
};

const calculateCarryWeight = (stat, bonuses) => {
  const base = 30;
  const bonus = calculateBonus(stat,bonuses, 'endurance', 0,3,'*');
  const additionalBonus = bonuses.carryWeight ||0;
  return  Math.round(base + bonus + additionalBonus);
};

const calculateOverloadlight = (stat, bonuses) => {
  const base = 30;
  const bonus = calculateBonus(stat,bonuses, 'endurance', 0,3,'*');
  const additionalBonus = bonuses.carryWeight ||0;
  return  Math.round((base + bonus + additionalBonus)*1.2);
};

const calculateOverloadMedium = (stat, bonuses) => {
  const base = 30;
  const bonus = calculateBonus(stat,bonuses, 'endurance', 0,3,'*');
  const additionalBonus = bonuses.carryWeight ||0;
  return  Math.round((base + bonus + additionalBonus)*1.5);
};

const calculateInitiative = (stat, bonuses) => {
  const base = 0;
  const bonus = calculateBonus(stat,bonuses, 'intelligence', 0,5,'*');
  const additionalBonus = bonuses.Initiative ||0;
  return  Math.round(base + bonus + additionalBonus);
};

// Обновленная функция calculateResist
const calculateResist = (stats, bonuses = {}) => {
  const base = 0;
  const resistBonus = calculateBonus(stats, bonuses, 'resist', base); // Используем calculateBonus для расчета бонуса от stats
  let resist = resistBonus + base; // Добавляем бонусы из bonuses

  if (bonuses.sc_armor) {
    for (const level in bonuses.sc_armor) {
      if (bonuses.sc_armor[level] === 'Resist') {
        resist += 1; // Добавляем дополнительные бонусы из sc_armor
      }
    }
  }
  return resist;
};

const calculateFireResist = (stats, bonuses) => {
  const baseFireResist = stats.main?.resist?.defaultValue || 0;
  const resistElementalMagicBonus = calculateBonus(stats, bonuses, 'resist_elemental_magic', 0); // Рассчитываем бонус от resist_elemental_magic
  const resistFireResist = calculateBonus(stats, bonuses, 'FireResist', 0);
  const resist = calculateResist(stats, bonuses);
  const totalFireResist = baseFireResist + resist + resistElementalMagicBonus + resistFireResist;
  return totalFireResist;
};

const calculateIceResist = (stats, bonuses) => {
  const baseFireResist = stats.main?.resist?.defaultValue || 0;
  const resistElementalMagicBonus = calculateBonus(stats, bonuses, 'resist_elemental_magic', 0);
  const resistIceResist = calculateBonus(stats, bonuses, 'IceResist', 0); // Рассчитываем бонус от resist_elemental_magic
  const resist = calculateResist(stats, bonuses);
  const totalFireResist = baseFireResist + resist + resistElementalMagicBonus + resistIceResist;
  return totalFireResist;
};

const calculateEarthResist = (stats, bonuses) => {
  const baseFireResist = stats.main?.resist?.defaultValue || 0;
  const resistElementalMagicBonus = calculateBonus(stats, bonuses, 'resist_elemental_magic', 0); // Рассчитываем бонус от resist_elemental_magic
  const resistEarthResist = calculateBonus(stats, bonuses, 'EarthResist', 0);
  const resist = calculateResist(stats, bonuses);
  const totalFireResist = baseFireResist + resist + resistElementalMagicBonus + resistEarthResist;
  return totalFireResist;
};

const calculateAirResist = (stats, bonuses) => {
  const baseFireResist = stats.main?.resist?.defaultValue || 0;
  const resistElementalMagicBonus = calculateBonus(stats, bonuses, 'resist_elemental_magic', 0); // Рассчитываем бонус от resist_elemental_magic
  const resistAirResist = (stats, bonuses, 'AirResist', 0);
  const resist = calculateResist(stats, bonuses);
  const totalFireResist = baseFireResist + resist + resistElementalMagicBonus + resistAirResist;
  return totalFireResist;
};

const calculateArmorPool = (stats,bonuses) => {
  return calculateBonus(stats,bonuses,'armor_pool');
};

const calculateRequirement_threshold_armor = (stats,bonuses) => {
  return calculateBonus(stats,bonuses,'requirement_threshold_armor');
};

const calculateReduce_AP_armor_penalty = (stats,bonuses) => {
  return calculateBonus(stats,bonuses,'reduce_AP_armor_penalty');
};

const calculateNo_penalty_armor = (stats,bonuses) => {
  return calculateBonus(stats,bonuses,'no_penalty_armor');
};

const calculatePenalty_armor = (stats,bonuses) => {
  return calculateBonus(stats,bonuses,'penaltyArmor');
};

const calculateMeleeWeaponsPool = (stats,bonuses) => {
  return calculateBonus(stats,bonuses,'melee_weapons_pool');
};

const calculateParry =  (stat,bonuses) => {
  const base = 0;
  const bonus = 0;
  const additionalBonus = bonuses.Parry ||0;
  return base + bonus + additionalBonus;
};

const calculateHP_reg = (stats, bonuses = {}) => {
  const baseHP_reg = 0;
  const HP_regBonus = calculateBonus(stats, bonuses, 'HP_reg', baseHP_reg); // Используем calculateBonus для расчета бонуса от stats
  const additionalBonusHP_reg = bonuses.HP_reg || 0;
  const totalHP_reg = HP_regBonus + additionalBonusHP_reg;
  return totalHP_reg;
};

const calculateMP_reg = (stats, bonuses = {}) => {
  const baseMP_reg = 0;
  const MP_regBonus = calculateBonus(stats, bonuses, 'MP_reg', baseMP_reg); // Используем calculateBonus для расчета бонуса от stats
  const additionalBonusMP_reg = bonuses.HP_reg || 0;
  const totalMP_reg = MP_regBonus + additionalBonusMP_reg;
  return totalMP_reg;
};

const calculatereduction_MP_costs = (stats, bonuses = {}) => {
  const basereduction_MP_costs = 0;
  const reduction_MP_costsBonus = 0;
  const additionalBonusreduction_MP_costs = bonuses.reduction_MP_costs || 0;
  const totalreduction_MP_costs = basereduction_MP_costs + reduction_MP_costsBonus+additionalBonusreduction_MP_costs;
  return totalreduction_MP_costs;
};

const calculatedgm_hill_per_spell = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.dgm_hill_per_spell ||0;
  return  base + bonus + additionalBonus;
};

const calculateRequirement_threshold_melee_weapon = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.requirement_threshold_melee_weapon ||0;
  return  base + bonus + additionalBonus;
};

const calculateRage_per_melee_attack = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.rage_per_melee_attack ||0;
  return  base + bonus + additionalBonus;
};

const calculatePathOfTheBodyPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.path_of_the_body_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateElementalMagicPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.elemental_magic_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateblood_pool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.blood_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateLightMagicPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.light_magic_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateDarkMagicPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.dark_magic_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateBloodMagicPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.blood_magic_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateSummonerPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.summoner_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateAstralPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.astral_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateRitualMagicPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ritual_magic_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateThrownWeaponsPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.thrown_weapons_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateRangedWeaponsPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ranged_weapons_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculatePotionPulls = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.potion_pulls ||0;
  return  base + bonus + additionalBonus;
};

const calculateShieldPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.shield_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateCautionPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.caution_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateSurvivalPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.survival_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateMedicinePool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.medicine_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateArtOfWarPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.art_of_war_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculatePathOfTheSpiritPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.path_of_the_spirit_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateUnscrupulousnessPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.unscrupulousness_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateMasteryOfMagicalToolsPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.mastery_of_magical_tools_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateMerchantPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.merchant_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateCraftsmanshipPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.craftsmanship_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateBeastmasterPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.beastmaster_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateWitcherPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.witcher_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateInquisitorPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.inquisitor_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateNecromancyPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.necromancy_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateNatureMagicPool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.nature_magic_pool ||0;
  return  base + bonus + additionalBonus;
};

const calculateAttackSum = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.AttackSum ||0;
  return  base + bonus + additionalBonus;
};

const calculateReflectionSum = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ReflectionSum ||0;
  return  base + bonus + additionalBonus;
};

const calculateResistSum = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ResistSum ||0;
  return  base + bonus + additionalBonus;
};

const calculateArmorSum = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ArmorSum ||0;
  return  base + bonus + additionalBonus;
};

const calculateMagicDamageSum = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.MagicDamageSum ||0;
  return  base + bonus + additionalBonus;
};

const calculatereduce_MP_cost_magic = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.reduce_MP_cost_magic ||0;
  return  base + bonus + additionalBonus;
};

const calculateRequirementThreshold = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.RequirementThreshold ||0;
  return  base + bonus + additionalBonus;
};

const calculateAttackMagicTool = (stat, bonuses) => {
  const base = 30;
  const bonus = 0
  const additionalBonus = bonuses.AttackMagicTool ||0;
  return  base + bonus + additionalBonus;
};

const calculateAttackBeast = (stat, bonuses) => {
  const base = 30;
  const bonus = 0
  const additionalBonus = bonuses.AttackAttackBeast ||0;
  return  base + bonus + additionalBonus;
};

const calculateAttackSpell = (stat, bonuses) => {
  const base = 30;
  const bonus = 0
  const additionalBonus = bonuses.AttackAttackSpell ||0;
  return  base + bonus + additionalBonus;
};

const calculatePureDamageToTargetWithHigherMaxHPThanWitcher = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.PureDamageToTargetWithHigherMaxHPThanWitcher ||0;
  return  base + bonus + additionalBonus;
};

const calculateElementalResistMagicTool = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ElementalResistMagicTool ||0;
  return  base + bonus + additionalBonus;
};

const calculateArmorBeast = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ArmorBeast ||0;
  return  base + bonus + additionalBonus;
};

const calculateQuickSlotThrowing = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.QuickSlotThrowing ||0;
  return  base + bonus + additionalBonus;
};

const calculateCraftedArmorWeaponBonus = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.CraftedArmorWeaponBonus ||0;
  return  base + bonus + additionalBonus;
};

const calculateRingSlot = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.RingSlot ||0;
  return  base + bonus + additionalBonus;
};

const calculateSongActionPoints = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.SongActionPoints ||0;
  return  base + bonus + additionalBonus;
};

const calculateArmorPenetration = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ArmorPenetration ||0;
  return  base + bonus + additionalBonus;
};

const calculateBleedDamageIgnore = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.BleedDamageIgnore ||0;
  return  base + bonus + additionalBonus;
};

const calculatePoisonDamageIgnore = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.PoisonDamageIgnore ||0;
  return  base + bonus + additionalBonus;
};

const calculateTrapAtStart = (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.TrapAtStart ||0;
  return  base + bonus + additionalBonus;
};

const calculateCrit= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Crit ||0;
  return  base + bonus + additionalBonus;
};

const calculateFirstTurnActionPoints= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.FirstTurnActionPoints ||0;
  return  base + bonus + additionalBonus;
};

const calculateFirstTurnReflection= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.FirstTurnReflection ||0;
  return  base + bonus + additionalBonus;
};

const calculateParryAttempts= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ParryAttempts ||0;
  return  base + bonus + additionalBonus;
};

const calculatePotionSelfBonus= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.PotionSelfBonus ||0;
  return  base + bonus + additionalBonus;
};

const calculateRageGain= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.RageGain ||0;
  return  base + bonus + additionalBonus;
};

const calculateShieldDurability= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ShieldDurability ||0;
  return  base + bonus + additionalBonus;
};

const calculateResistDestruction= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ResistDestruction ||0;
  return  base + bonus + additionalBonus;
};

const calculateResistBeast= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ResistBeast ||0;
  return  base + bonus + additionalBonus;
};

const calculateNatureMagicResist= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.NatureMagicResist ||0;
  return  base + bonus + additionalBonus;
};

const calculateDarkMagicResist= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.DarkMagicResist ||0;
  return  base + bonus + additionalBonus;
};

const calculateToolResource= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ToolResource ||0;
  return  base + bonus + additionalBonus;
};

const calculateReduce_MP_Cost= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Reduce_MP_Cost ||0;
  return  base + bonus + additionalBonus;
};
const calculateReduce_MP_Cost_Astral= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Reduce_MP_Cost_Astral ||0;
  return  base + bonus + additionalBonus;
};
const calculateReduce_HP_Cost= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Reduce_HP_Cost ||0;
  return  base + bonus + additionalBonus;
};
const calculateActionPointPenaltyReduction= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ActionPointPenaltyReduction ||0;
  return  base + bonus + additionalBonus;
};
const calculateElementalMagicResist= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.calculateElementalMagicResist ||0;
  return  base + bonus + additionalBonus;
};

const calculateArmorAttackPenaltyReduction= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ArmorAttackPenaltyReduction ||0;
  return  base + bonus + additionalBonus;
};

const calculateBleedIncrease= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.BleedIncrease ||0;
  return  base + bonus + additionalBonus;
};

const calculateHealingIncrease= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.HealingIncrease ||0;
  return  base + bonus + additionalBonus;
};

const calculatPoisonIncrease= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.PoisonIncrease ||0;
  return  base + bonus + additionalBonus;
};

const calculateDurabilityIncrease= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.DurabilityIncrease ||0;
  return  base + bonus + additionalBonus;
};

const calculateFirstTurnEvasion= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.FirstTurnEvasion ||0;
  return  base + bonus + additionalBonus;
};

const calculateEvasion_Beast= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Evasion_Beast ||0;
  return  base + bonus + additionalBonus;
};

const calculateMagicToolDamageHealing= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.MagicToolDamageHealing ||0;
  return  base + bonus + additionalBonus;
};

const calculateDamage_Beast= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Damage_Beast ||0;
  return  base + bonus + additionalBonus;
};

const calculateElementalDamage= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.ElementalDamage ||0;
  return  base + bonus + additionalBonus;
};

const calculateUndeadDamageAndSpellBonus= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.UndeadDamageAndSpellBonus ||0;
  return  base + bonus + additionalBonus;
};

const calculateStartBattleRage= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.StartBattleRage ||0;
  return  base + bonus + additionalBonus;
};

const calculateDamageHealing_Per_Spell= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.DamageHealing_Per_Spell ||0;
  return  base + bonus + additionalBonus;
};

const calculateHit_Points_Beast= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Hit_Points_Beast ||0;
  return  base + bonus + additionalBonus;
};

const calculateHit_Points_Undead= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.Hit_Points_Undead ||0;
  return  base + bonus + additionalBonus;
};

const calculateGreatQualityChance= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.GreatQualityChance ||0;
  return  base + bonus + additionalBonus;
};

const calculateGoodQualityCraftingChance= (stat, bonuses) => {
  const base = 0;
  const bonus = 0
  const additionalBonus = bonuses.GoodQualityCraftingChance ||0;
  return  base + bonus + additionalBonus;
};


export {
  calculateDamage_Beast,calculateElementalDamage,calculateUndeadDamageAndSpellBonus,calculateStartBattleRage,calculateDamageHealing_Per_Spell,calculateHit_Points_Beast,calculateHit_Points_Undead,calculateGreatQualityChance,calculateGoodQualityCraftingChance,
  calculateArmorAttackPenaltyReduction,calculateBleedIncrease,calculateHealingIncrease,calculatPoisonIncrease,calculateDurabilityIncrease,calculateFirstTurnEvasion,calculateEvasion_Beast,calculateMagicToolDamageHealing,
  calculateToolResource,calculateReduce_MP_Cost,calculateReduce_MP_Cost_Astral,calculateReduce_HP_Cost,calculateActionPointPenaltyReduction,
  calculateMaxHP, calculateCurrentLevelAndRequiredExp, calculateMaxMP,calculateAttackMagicTool,calculateAttackBeast,calculateAttackSpell,calculatePureDamageToTargetWithHigherMaxHPThanWitcher,calculateElementalResistMagicTool,
  calculateArmorBeast,calculateQuickSlotThrowing,calculateCraftedArmorWeaponBonus,calculateRingSlot,calculateSongActionPoints,calculateArmorPenetration,calculateBleedDamageIgnore,calculatePoisonDamageIgnore,calculateTrapAtStart,
  calculateCrit,calculateFirstTurnActionPoints,calculateFirstTurnReflection,calculateParryAttempts,calculatePotionSelfBonus,calculateRageGain,calculateShieldDurability,calculateResistDestruction,calculateResistBeast,calculateNatureMagicResist,
  calculateDarkMagicResist,calculateElementalMagicResist,
  calculateAttack, calculateDamage, calculateMagicDamage, calculateRangedDamage,
  calculateThrowDamage, calculatePeriodicDamage, calculateAreaMagicDamage,
  calculateInitiative,
  calculateCarryWeight, calculateActionPoints, calculateTravelActionPoints, calculateReflection,
  calculateEvasion, calculateArmor, calculateOverloadlight, calculateOverloadMedium,
  calculateResist, calculateFireResist, calculateIceResist, calculateRequirement_threshold_armor,
  calculateReduce_AP_armor_penalty, calculateNo_penalty_armor, calculatePenalty_armor, calculateParry, calculateRequirement_threshold_melee_weapon,
  calculateRage_per_melee_attack,  calculateMeleeWeaponsPool,
  calculateArmorPool,
  calculatePathOfTheBodyPool,
  calculateElementalMagicPool,
  calculateLightMagicPool,
  calculateDarkMagicPool,
  calculateBloodMagicPool,
  calculateRuneMagicPool,
  calculateSummonerPool,
  calculateAstralPool,
  calculateRitualMagicPool,
  calculateThrownWeaponsPool,
  calculateRangedWeaponsPool,
  calculatePotionPulls,
  calculateShieldPool,
  calculateCautionPool,
  calculateSurvivalPool,
  calculateMedicinePool,
  calculateArtOfWarPool,
  calculatePathOfTheSpiritPool,
  calculateUnscrupulousnessPool,
  calculateMasteryOfMagicalToolsPool,
  calculateMerchantPool,
  calculateCraftsmanshipPool,
  calculateBeastmasterPool,
  calculateWitcherPool,
  calculateInquisitorPool,
  calculateNecromancyPool,
  calculateNatureMagicPool,
  calculateHP_reg,
  calculateMP_reg, calculateblood_pool,calculatedgm_hill_per_spell,calculatereduction_MP_costs,calculateAirResist,
  calculateEarthResist,calculateMagicDamageSum,calculateArmorSum,calculateResistSum,calculateReflectionSum,calculateAttackSum,calculatereduce_MP_cost_magic,calculateRequirementThreshold
};